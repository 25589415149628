import module from '@admin/usuario/module'
import template from './template.html'

export class AdminUsuarioComponent {
  constructor(AdminUsuarioService) {
    this.usuarioService = AdminUsuarioService
    this.idUsuario = undefined
  }

  $onInit() {
    this.promise = this.usuarioService.get(this.idUsuario).then((response) => (this.data = response.data))
  }
}

module.component('adminUsuario', {
  controller: AdminUsuarioComponent,
  template,
  bindings: {
    idUsuario: '<',
  },
})
