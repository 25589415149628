import module from '@admin/usuario/module'
import template from './template.html'

export class AdminUsuarioSesionComponent {
  constructor(AdminUsuarioSesionService) {
    this.idUsuario = undefined
    this.AdminUsuarioSesionService = AdminUsuarioSesionService
    this.VISTA_ACCESO = 1
    this.vista = null
  }

  $onInit() {
    this.promise = this.AdminUsuarioSesionService.get(this.idUsuario).then((response) => (this.data = response.data))
  }

  actualizar(item, data) {
    Object.assign(item, data)
  }

  ver(vista) {
    this.vista = vista || null
  }
}

module.component('adminUsuarioSesion', {
  controller: AdminUsuarioSesionComponent,
  template,
  bindings: {
    idUsuario: '<',
  },
})
