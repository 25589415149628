import module from '@app/sesion/module'
import template from './template.html'
import './style.scss'

export class AppSesionAlarmaComponent {
  constructor($timeout, $session) {
    this.$timeout = $timeout
    this.$session = $session
    this.alarmaThreshold = 60
    this.token = undefined
    this.notificacion = undefined
  }

  $onChanges(changes) {
    if (changes.token) {
      this.$timeout.cancel(this.alarmTimer)
      this.$timeout.cancel(this.expiresTimer)

      this.actualizarAlarmas()
    }
  }

  actualizarAlarmas() {
    if (!this.token) return

    const tiempoExpiracion = new Date(this.token.exp * 1000)
    const tiempoActual = new Date()
    let tiempoAlarma = null

    if (tiempoExpiracion.getTime() > tiempoActual.getTime()) {
      tiempoAlarma = new Date((this.token.exp - this.alarmaThreshold) * 1000)

      this.alarmTimer = this.$timeout(() => this.alertar(), tiempoAlarma.getTime() - tiempoActual.getTime())
      this.expiresTimer = this.$timeout(
        () => this.sesionTerminar(),
        tiempoExpiracion.getTime() - tiempoActual.getTime(),
      )
    }
  }

  alertar() {
    if (this.notificacion) this.notificacion.open()
  }

  sesionTerminar() {
    this.$session.unauth()
  }
}

module.component('appSesionAlarma', {
  controller: AppSesionAlarmaComponent,
  template,
  bindings: {
    token: '<',
  },
})
