import module from 'kendu/legacy/module'
import template from './template.html'
import './style.scss'

export class InputFileComponent {
  constructor($element, $fileSystem, $window) {
    this.$element = $element
    this.$fileSystem = $fileSystem
    this.$window = $window
    this.model = undefined
    this.render = this.render.bind(this)
    this.validateFileType = this.validateFileType.bind(this)
    this.accept = undefined
  }

  $onInit() {
    this.$element.attr('tabindex', '0')

    this.ngModel.$render = this.render
    this.ngModel.$validators.fileType = this.validateFileType
  }

  $onChanges(changes) {
    if (changes.accept) {
      this.tester = this.accept ? new RegExp(this.accept) : null
    }
  }

  render() {
    this.model = this.ngModel.$modelValue
  }

  select() {
    this.$fileSystem.select(false, this.accept).then((files) => {
      this.model = files[0]
      this.ngModel.$setViewValue(this.model)
    })
  }

  remove() {
    this.model = undefined
    this.ngModel.$setViewValue(this.model)
  }

  validateFileType(value) {
    return !value || (value instanceof this.$window.File && (!this.tester || this.tester.test(value.type)))
  }
}

module.component('inputFile', {
  controller: InputFileComponent,
  template,
  require: {
    ngModel: 'ngModel',
  },
  bindings: {
    accept: '@',
    placeholder: '@',
  },
})
