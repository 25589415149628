import module from '@kendu/ui/module'
import './style.scss'

class KdModalDirective {
  constructor($attrs, $scope, $transclude, $q, $parse, KdModalService) {
    const parser = $parse($attrs.kdModal)

    if (!parser.assign) return

    this.$transclude = $transclude
    this.$q = $q
    this.KdModalService = KdModalService
    this.modal = null
    this.promise = null
    this.assign = (value) => parser.assign($scope, value)
  }

  $onDestroy() {
    this.cancel()
  }

  $onInit() {
    if (this.assign) {
      this.assign({
        open: () => this.open(),
        close: (data) => this.close(data),
      })
    }
  }

  open() {
    if (this.modal) return this.modal.promise

    this.$transclude((element, scope) => {
      element.on('destroy', () => this.cancel())
      this.modal = {
        element,
        destroy: () => {
          this.KdModalService.remove(element)
          scope.$destroy()
        },
      }
    })

    this.KdModalService.add(this.modal.element)
    this.promise = this.$q.defer()

    return this.promise.promise
  }

  close(data) {
    if (this.promise) {
      this.promise.resolve(data)
      this.promise = null
    }

    this.update()
  }

  cancel() {
    if (this.promise) {
      this.promise.reject()
      this.promise = null
    }

    this.assign(undefined)
    this.update()
  }

  update() {
    if (this.modal) {
      this.modal.destroy()
      this.modal = null
    }
  }
}

module.directive('kdModal', () => ({
  controller: KdModalDirective,
  restrict: 'A',
  transclude: 'element',
  priority: 500,
  terminal: true,
}))
