import module from 'kendu/legacy/module'

export class $session {
  constructor($http, $localStorage, $jwt) {
    this.$http = $http
    this.$localStorage = $localStorage
    this.$jwt = $jwt
    this.authPromise = undefined
  }

  current() {
    return this.$http.get('api/app/sesion/current', { cache: true }).then((data) => data)
  }

  auth(credencial) {
    if (!this.authPromise) {
      this.authPromise = this.$http
        .post('api/app/sesion/token', credencial)
        .then((response) => {
          this.setSession(response.data.token)

          return response
        })
        .finally(() => (this.authPromise = undefined))
    }

    return this.authPromise
  }

  unauth() {
    this.setSession(null)
  }

  isAuth() {
    return this.$localStorage.token && this.$jwt.isExpired(this.$localStorage.token) === false
  }

  renew() {
    if (!this.authPromise) {
      this.authPromise = this.$http
        .put('api/app/sesion/token')
        .then((response) => this.setSession(response.data.token))
        .finally(() => (this.authPromise = undefined))
    }

    return this.authPromise
  }

  setSession(jwt) {
    if (jwt) {
      this.$localStorage.token = jwt
    } else {
      delete this.$localStorage.token
    }
  }
}

module.service('$session', $session)
