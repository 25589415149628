import module from '@kendu/ui/module'

class KdCacheInterceptor {
  constructor(AppHttpCache) {
    this.AppHttpCache = AppHttpCache
  }

  request = (config) => {
    if (typeof config.cache === 'string') {
      config.cache = this.AppHttpCache.get(config.cache)
    }

    return config
  }

  response = (response) => {
    if (typeof response.config.clearCache === 'string') {
      this.AppHttpCache.clear(response.config.clearCache)
    } else if (response.config.clearCache === true) {
      this.AppHttpCache.destroy()
    }

    return response
  }
}

module.service('KdCacheInterceptor', KdCacheInterceptor)
