import module from '@kendu/ui/module'
import template from './template.html'
import iconInfo from './icons/info.html'
import iconSuccess from './icons/success.html'
import iconWarning from './icons/warning.html'
import iconError from './icons/error.html'
import './style.scss'

export const TYPE_INFO = 'info'
export const TYPE_SUCCESS = 'success'
export const TYPE_WARNING = 'warning'
export const TYPE_ERROR = 'error'

export class KdAlertComponent {
  constructor($attrs, $sce) {
    this.type = undefined
    this.onClose = undefined
    this.$attrs = $attrs
    this.$sce = $sce
    this.dismisable = false
  }

  $onChanges(changes) {
    if (changes.type) {
      this.icon = this.getIcon()
    }
  }

  $onInit() {
    this.$attrs.$observe('onClose', (value) => (this.dismisable = !!value))
  }

  getIcon() {
    switch (this.type) {
      case TYPE_SUCCESS:
        return this.$sce.trustAsHtml(iconSuccess)

      case TYPE_WARNING:
        return this.$sce.trustAsHtml(iconWarning)

      case TYPE_ERROR:
        return this.$sce.trustAsHtml(iconError)

      case TYPE_INFO:
      default:
        return this.$sce.trustAsHtml(iconInfo)
    }
  }

  close($event) {
    this.onClose({ $event })
  }
}

module.component('kdAlert', {
  controller: KdAlertComponent,
  template,
  transclude: {},
  bindings: {
    type: '@',
    onClose: '&',
  },
})
