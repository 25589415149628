import module from '@app/sesion/module'

module.config(($stateProvider) => {
  $stateProvider.state('sesion.registrar', {
    url: '/registrar/{token}',
    data: { title: 'Registrar' },
    component: 'appSesionRegistrar',
    resolve: {
      token: ($transition$) => $transition$.params().token,
    },
  })
})
