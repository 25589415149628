import module from '@admin/usuarios/module'
import template from './template.html'

export class AdminUsuariosAgregarConfirmarComponent {
  constructor($state, AdminUsuariosService) {
    this.data = undefined
    this.modal = undefined
    this.$state = $state
    this.AdminUsuariosService = AdminUsuariosService
    this.error = null
    this.procesando = false
    this.resultado = undefined
  }

  agregar() {
    if (this.procesando) return

    this.error = null
    this.procesando = true

    this.AdminUsuariosService.post(this.data)
      .then((response) => (this.resultado = response.data))
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }

  cerrar() {
    this.modal.close(this.resultado)
  }

  nuevo() {
    this.$state.go('usuarios.agregar', {}, { reload: true })
  }
}

module.component('adminUsuariosAgregarConfirmar', {
  controller: AdminUsuariosAgregarConfirmarComponent,
  template,
  bindings: {
    data: '<',
  },
  require: {
    modal: 'kdModal',
  },
})
