import module from '@app/sesion/module'
import template from './template.html'
import './style.scss'
import './ayuda/component.js'
import './activar/component.js'

export class AppSesionRegistrarComponent {
  constructor(AppSesionRegistrarService) {
    this.registrarService = AppSesionRegistrarService
    this.completado = false
    this.token = undefined
    this.AppSesionRegistrarActivar = undefined
    this.ayuda = undefined
    this.validadorPassword = {
      strength: (value) => {
        this.password_strength = this.scorePassword(value)

        return this.password_strength >= 50
      },
    }
    this.validadorConfirmarPassword = {
      passmatch: (value) => this.data.password === value,
    }
  }

  $onInit() {
    this.promise = this.registrarService.get(this.token).then((response) => (this.data = response.data))
  }

  activar() {
    this.AppSesionRegistrarActivar.open()
      .then((completado) => (this.completado = completado))
      .catch((error) => (this.error = error))
  }

  mostrarAyuda() {
    this.ayuda.open()
  }

  scorePassword(pass) {
    let score = 0

    if (!pass) return score

    const letters = {}

    for (let i = 0; i < pass.length; i++) {
      letters[pass[i]] = (letters[pass[i]] || 0) + 1
      score += 5.0 / letters[pass[i]]
    }

    const variations = {
      digits: /\d/.test(pass),
      lower: /[a-z]/.test(pass),
      upper: /[A-Z]/.test(pass),
      nonWords: /\W/.test(pass),
    }

    let variationCount = 0

    for (const variation of Object.values(variations)) {
      variationCount += variation ? 1 : 0
    }

    score += (variationCount - 1) * 10

    return Math.min(score, 100)
  }
}

module.component('appSesionRegistrar', {
  controller: AppSesionRegistrarComponent,
  template,
  bindings: {
    token: '<',
  },
})
