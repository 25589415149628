import module from '@app/sesion/module'

export const appSesionReestablecerSchema = {}

export class AppSesionReestablecerService {
  constructor($http) {
    this.$http = $http
  }

  post(data) {
    return this.$http.post(`api/app/sesion/reestablecer`, data, {
      schema: appSesionReestablecerSchema,
    })
  }
}

module.service('AppSesionReestablecerService', AppSesionReestablecerService)
