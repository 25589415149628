import module from 'kendu/legacy/module'

module.directive('percentage', () => ({
  restrict: 'A',
  require: 'ngModel',
  link(scope, element, attrs, ngModel) {
    const roundTo = Number(attrs.percentage) || 2
    const input = element[0]

    element.bind('focus', render)
    element.bind('blur', render)

    ngModel.$render = render
    ngModel.$parsers.push(parser)

    if (ngModel.$validators.min) {
      ngModel.$validators.min = (val) => val >= element.attr('min') / 100
    }

    if (ngModel.$validators.max) {
      ngModel.$validators.max = (val) => val <= element.attr('max') / 100
    }

    function render() {
      const value = ngModel.$modelValue

      if (input === document.activeElement) {
        input.type = 'number'
        input.value = ngModel.$isEmpty(value) ? value : (value * 100).toFixed(roundTo - 2)
      } else {
        input.type = 'text'
        input.value = ngModel.$isEmpty(value) ? '' : (value * 100).toFixed(roundTo - 2) + '%'
      }
    }

    function parser(value) {
      return ngModel.$isEmpty(value) ? value : Number((value / 100).toFixed(roundTo))
    }
  },
}))
