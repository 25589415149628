import module from '@kendu/ui/module'

export class KdBreadcrumbs {
  constructor($state, $timeout, $translate, $q) {
    this.state = $state
    this.timeout = $timeout
    this.translate = $translate
    this.q = $q
    this.displaynameProperty = 'data.title'
    this.abstractProxyProperty = 'data.titleProxy'
    this.breadcrumbs = []
  }

  Breadcrumb(displayName, route) {
    return this.translate(displayName)
      .then((name) => ({ displayName: name, route }))
      .catch((name) => ({ displayName: name, route }))
  }

  update() {
    let workingState
    let displayName
    const breadcrumbs = []
    const routes = []
    const pending = []
    let currentState = this.state.$current

    while (currentState && currentState.name !== '') {
      workingState = this.getWorkingState(currentState)

      if (workingState) {
        displayName = this.getDisplayName(workingState)

        if (displayName !== false && !this.stateAlreadyInBreadcrumbs(workingState, routes)) {
          pending.push(this.Breadcrumb(displayName, workingState.name))
          routes.push(workingState.name)
        }
      }

      currentState = currentState.parent
    }

    return this.q.all(pending).then((states) => {
      const title = []

      states.forEach((state) => {
        breadcrumbs.push(state)
        title.unshift(state.displayName)
      })

      this.breadcrumbs.length = 0

      breadcrumbs.reverse().forEach((item) => this.breadcrumbs.push(item))

      return title
    })
  }

  getWorkingState(currentState) {
    let proxyStateName
    let workingState = currentState

    if (currentState.abstract === true) {
      if (typeof this.abstractProxyProperty !== 'undefined') {
        proxyStateName = this.getObjectValue(this.abstractProxyProperty, currentState)

        if (proxyStateName) {
          workingState = this.state.get(proxyStateName)
        } else {
          workingState = false
        }
      } else {
        workingState = false
      }
    }
    return workingState
  }

  getDisplayName(currentState) {
    return this.displaynameProperty ? this.getObjectValue(this.displaynameProperty, currentState) : currentState.name
  }

  getObjectValue(objectPath, context) {
    const propertyArray = objectPath.split('.')
    let propertyReference = context

    for (let i = 0; i < propertyArray.length; i++) {
      if (typeof propertyReference[propertyArray[i]] !== 'undefined') {
        propertyReference = propertyReference[propertyArray[i]]
      } else {
        return undefined
      }
    }
    return propertyReference
  }

  stateAlreadyInBreadcrumbs(state, breadcrumbs) {
    let alreadyUsed = false

    for (let i = 0; i < breadcrumbs.length; i++) {
      if (breadcrumbs[i] === state.name) {
        alreadyUsed = true
      }
    }
    return alreadyUsed
  }
}

module.service('KdBreadcrumbs', KdBreadcrumbs)
