import module from '@app/sesion/module'

export class AppUiThemeService extends EventTarget {
  constructor(AppUiSystemSchemeService, AppUiUserSchemeService) {
    super()
    this.appUiSystemSchemeService = AppUiSystemSchemeService
    this.appUiUserSchemeService = AppUiUserSchemeService
    this.appUiSystemSchemeService.addEventListener('change', this.emit)
    this.appUiUserSchemeService.addEventListener('change', this.emit)
  }

  get systemScheme() {
    return this.appUiSystemSchemeService.getScheme()
  }

  get userScheme() {
    return this.appUiUserSchemeService.getScheme()
  }

  get scheme() {
    return this.userScheme === 'auto' ? this.systemScheme : this.userScheme
  }

  set scheme(value) {
    this.appUiUserSchemeService.setScheme(value)
  }

  toggle() {
    const scheme = this.systemScheme === this.scheme ? (this.scheme === 'dark' ? 'light' : 'dark') : this.systemScheme

    this.scheme = scheme
  }

  emit = () => {
    const event = new CustomEvent('change', { detail: { scheme: this.scheme } })
    this.dispatchEvent(event)
  }
}

module.service('AppUiThemeService', AppUiThemeService)
