import module from '@admin/usuario/module'
import template from './template.html'

export class AdminUsuarioPermisosComponent {
  constructor(AdminUsuarioPermisosService) {
    this.idUsuario = undefined
    this.AdminUsuarioPermisosService = AdminUsuarioPermisosService
  }

  $onInit() {
    this.promise = this.AdminUsuarioPermisosService.get(this.idUsuario).then((response) => (this.data = response.data))
  }
}

module.component('adminUsuarioPermisos', {
  controller: AdminUsuarioPermisosComponent,
  template,
  bindings: {
    idUsuario: '<',
  },
})
