import module from '@admin/usuario/module'
import template from './template.html'

export class AdminUsuarioInvitacionComponent {
  constructor($state, AdminUsuarioInvitacionService) {
    this.idUsuario = undefined
    this.$state = $state
    this.AdminUsuarioInvitacionService = AdminUsuarioInvitacionService
    this.error = null
    this.procesando = false
  }

  $onInit() {
    this.promise = this.AdminUsuarioInvitacionService.get(this.idUsuario).then(
      (response) => (this.data = response.data),
    )
  }

  cerrar() {
    this.$state.go('usuario')
  }

  enviar() {
    if (this.procesando) return

    this.error = null
    this.procesando = true

    const data = {
      email: this.data.email,
    }

    this.AdminUsuarioInvitacionService.put(this.idUsuario, data)
      .then((response) => (this.respuesta = response.data))
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }
}

module.component('adminUsuarioInvitacion', {
  controller: AdminUsuarioInvitacionComponent,
  template,
  bindings: {
    idUsuario: '<',
  },
})
