import module from 'kendu/legacy/module'

export class ApiService {
  constructor($localStorage) {
    this.$localStorage = $localStorage
  }

  link(url, params = {}) {
    const query = []

    for (const [key, value] of Object.entries(params)) {
      if (Array.isArray(value)) {
        value.forEach((item) => query.push(key + '[]=' + item))
      } else if (typeof value !== 'undefined') {
        query.push(key + '=' + value)
      }
    }

    if (this.$localStorage.token) query.push(`&TOKEN=${this.$localStorage.token}`)

    return url + '?' + query.join('&')
  }
}

module.service('$api', ApiService)
