import module from '@admin/usuario/module'

export const adminUsuarioSesionHistorialSchema = {
  fecha: 'datetime',
}

export class AdminUsuarioSesionHistorialService {
  constructor($http) {
    this.$http = $http
  }

  get(idUsuario, fecha) {
    return this.$http.get(`api/usuario/${idUsuario}/sesion/historial`, {
      params: {
        periodo: fecha.getFullYear(),
        mes: fecha.getMonth() + 1,
      },
      schema: adminUsuarioSesionHistorialSchema,
    })
  }
}

module.service('AdminUsuarioSesionHistorialService', AdminUsuarioSesionHistorialService)
