import module from 'kendu/legacy/module'
import { element } from 'angular'

function $fileSystem($q, $log, $window) {
  this.select = select
  this.create = create

  function select(multiple, accept = '') {
    return $q((resolve, reject) => {
      const uploader = element('<input type="file">')

      uploader.attr('multiple', multiple === true)
      uploader.attr('accept', accept)

      uploader.on('change', function (event) {
        try {
          const files = []

          const regexps = accept instanceof RegExp ? [accept] : accept ? parseAccept(accept) : []

          for (const file of Object.values(event.target.files)) {
            if (file.type === '' || regexps.length === 0 || regexps.some((regexp) => regexp.test(file.type))) {
              files.push(file)
            } else {
              throw new Error('Invalid file type ' + file.type)
            }
          }
          resolve(files)
        } catch (e) {
          $log.error(e)
          reject(e)
        }
      })

      uploader[0].click()
    })
  }

  function create(data, content) {
    const name = data.name
    const type = data.type
    const lastModified = data.lastModified || (data.lastModifiedDate ? data.lastModifiedDate.getTime() : undefined)
    const lastModifiedDate = data.lastModifiedDate || (data.lastModified ? new Date(data.lastModified) : undefined)

    return new $window.File(content || [], name, {
      lastModified,
      lastModifiedDate,
      type,
    })
  }

  function parseAccept(string) {
    return string
      .replaceAll('/', '\\/')
      .replaceAll('*', '.+')
      .split(',')
      .map((test) => new RegExp(`^${test.trim()}$`))
  }
}

module.service('$fileSystem', $fileSystem)
