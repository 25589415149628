import module from '@kendu/ui/module'
import template from './template.html'
import './style.scss'

export class KdCheckboxComponent {
  ngModel = undefined

  constructor($scope, $attrs, $element) {
    this.$scope = $scope
    this.$attrs = $attrs
    this.$element = $element

    this.inputElement = $element[0].querySelector('input[type="checkbox"]')
    this.inputElement.addEventListener('change', () =>
      this.$scope.$apply(() => this.ngModel?.$setViewValue(this.checked)),
    )
    this.inputElement.addEventListener('click', (event) => event.stopPropagation())
  }

  $postLink() {
    if (!this.ngModel) return

    this.ngModel.$isEmpty = (value) => {
      return value !== true
    }

    this.ngModel.$render = () => {
      this.checked = this.ngModel.$modelValue
    }
  }

  get checked() {
    return this.inputElement.checked
  }

  set checked(value) {
    this.inputElement.checked = Boolean(value)
  }

  get disabled() {
    return this.inputElement.disabled
  }

  set disabled(value) {
    this.inputElement.disabled = Boolean(value)
  }

  get indeterminate() {
    return this.inputElement.indeterminate
  }

  set indeterminate(value) {
    this.inputElement.indeterminate = Boolean(value)
  }
}

module.component('kdCheckbox', {
  controller: KdCheckboxComponent,
  template,
  bindings: {
    checked: '<',
    disabled: '<',
    indeterminate: '<',
  },
  transclude: true,
  require: {
    ngModel: '?ngModel',
  },
})
