import module from '@admin/usuarios/module'

export const AdminUsuariosExisteSchema = {}

export class AdminUsuariosExisteService {
  constructor($http) {
    this.$http = $http
  }

  get(alias) {
    return this.$http.get(`api/usuarios/existe/${alias}`, {
      schema: AdminUsuariosExisteSchema,
    })
  }
}

module.service('AdminUsuariosExisteService', AdminUsuariosExisteService)
