import module from '@admin/usuarios/module'

module.config(($stateProvider) => {
  $stateProvider.state('usuarios.agregar', {
    url: '/agregar',
    data: { title: 'Agregar' },
    views: {
      '@': { component: 'adminUsuariosAgregar' },
    },
  })
})
