import module from '@admin/usuario/module'

module.config(($stateProvider) => {
  $stateProvider.state('usuario.permisos.clientes', {
    url: '/clientes',
    data: { title: 'Clientes' },
    views: {
      '@usuario': { component: 'adminUsuarioPermisosClientes' },
    },
  })
})
