import module from '@admin/usuario/module'

module.config(($stateProvider) => {
  $stateProvider.state('usuario.invitacion', {
    url: '/invitacion',
    data: { title: 'Activar cuenta' },
    views: {
      '@': { component: 'adminUsuarioInvitacion' },
    },
  })
})
