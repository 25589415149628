import module from '@admin/usuario/module'

export const adminUsuarioInformacionContactoSchema = {}

export class AdminUsuarioInformacionContactoService {
  constructor($http) {
    this.$http = $http
  }

  get(idUsuario) {
    return this.$http.get(`api/usuario/${idUsuario}/informacion/contacto`, {
      schema: adminUsuarioInformacionContactoSchema,
    })
  }

  put(idUsuario, data) {
    return this.$http.put(`api/usuario/${idUsuario}/informacion/contacto`, data, {
      schema: adminUsuarioInformacionContactoSchema,
    })
  }
}

module.service('AdminUsuarioInformacionContactoService', AdminUsuarioInformacionContactoService)
