import module from './module.js'
/* Styles */
import './directive/app/toolbar/style.scss'
import './directive/app/viewport/style.scss'
import './directive/inputFile/style.scss'
import './stylessheets/animations.scss'
import './stylessheets/flex.scss'
import './stylessheets/form-checkbox.scss'
import './stylessheets/h1.scss'
import './stylessheets/h2.scss'
import './stylessheets/h3.scss'
import './stylessheets/hgroup.scss'
import './stylessheets/hr.scss'
import './stylessheets/info.scss'
import './stylessheets/layout-text.scss'
import './stylessheets/layout.scss'
import './stylessheets/link.scss'
import './stylessheets/loadbar.scss'
import './stylessheets/notify.scss'
import './stylessheets/responsive.css'
import './stylessheets/tag.scss'
import './stylessheets/text.scss'
import './stylessheets/ui-view.scss'
import './stylessheets/ul.scss'
import './stylessheets/any/margin.scss'
import './stylessheets/any/padding.scss'
import './stylessheets/any/z.scss'
import './stylessheets/card/card.scss'
import './stylessheets/item/item.scss'
import './stylessheets/tabs/tabs.scss'
import './stylessheets/tabs/container/container.scss'
import './stylessheets/tabs/tab/tab.scss'
import './stylessheets/thumbnail/thumbnail.scss'
/* Directives */
import './directive/app/toolbar/component.js'
import './directive/app/viewport/component.js'
import './directive/combobox/component.js'
import './directive/currency/component.js'
import './directive/development/component.js'
import './directive/inputFile/component.js'
import './directive/percentage/component.js'
import './directive/ui-async-validator/component.js'
import './directive/ui-validators/component.js'
/* Filters */
import './filters/toPercent.js'
/* Services */
import './services/$api.js'
import './services/$fileSystem.js'
import './services/$jwt.js'
import './services/AppHttpCache.js'
import './services/session.js'

export default module.name
