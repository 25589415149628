import module from '@kendu/ui/module'

class ObjectSerializerService {
  constructor($log) {
    this.$log = $log
  }

  serialize(object, format) {
    return this.#transform(object, format, true)
  }

  unserialize(object, format) {
    return this.#transform(object, format, false)
  }

  #transform(object, format, stringify) {
    try {
      return transform(object, format, stringify)
    } catch (error) {
      this.$log.error(error?.message)

      return object
    }
  }
}

module.service('KdObjectSerializerService', ObjectSerializerService)

function transform(object, format, encode) {
  if (!isObject(object)) {
    return object
  }

  if (Array.isArray(object)) {
    return object.map((item) => transform(item, format, encode))
  }

  const result = structuredClone(object)

  for (const [property, type] of Object.entries(format)) {
    if (result && Object.hasOwn(result, property)) {
      if (isObject(type)) {
        result[property] = transform(result[property], type, encode)
      } else {
        result[property] = transformValue(result[property], type, encode)
      }
    }
  }

  return result
}

function isObject(value) {
  return Object(value) === value
}

function transformValue(value, type, stringify) {
  try {
    if (value === null || typeof value === 'undefined') {
      return value
    }

    switch (type) {
      case 'date':
        return stringify ? DATE.stringify(value) : DATE.parse(value)

      case 'timestamp':
      case 'datetime':
        return stringify ? DATETIME.stringify(value) : DATETIME.parse(value)

      case 'array':
        return stringify ? ARRAY.stringify(value) : ARRAY.parse(value)

      case 'number':
        return stringify ? NUMBER.stringify(value) : NUMBER.parse(value)

      case 'unixTimestamp':
        return stringify ? TIMESTAMP.stringify(value) : TIMESTAMP.parse(value)

      case 'boolean':
        return stringify ? BOOLEAN.stringify(value) : BOOLEAN.parse(value)

      case 'json':
        return stringify ? JSON.stringify(value) : JSON.parse(value)

      default:
        return value
    }
  } catch (error) {
    throw new Error(
      `Unable to ${stringify ? 'encode' : 'decode'} ${typeof value} ${value} to type ${type}: ${error.message}`,
    )
  }
}

const DATE = {
  stringify(value) {
    if (value === null) return '0000-00-00'

    return value.toJSON().slice(0, 10)
  },
  parse(value) {
    if (value === '0000-00-00') return null

    const [y, m, d] = value.slice(0, 19).split(/[^\d]/).map(Number)

    return new Date(y, m - 1, d)
  },
}

const DATETIME = {
  stringify(value) {
    if (value === null) return '0000-00-00 00:00:00'

    return value.toJSON().slice(0, 19).replace('T', ' ')
  },
  parse(value) {
    if (value === '0000-00-00 00:00:00') return null

    const [y, m, d, H, M, S] = value.slice(0, 19).split(/[^\d]/).map(Number)

    return new Date(y, m - 1, d, H, M, S)
  },
}

const TIMESTAMP = {
  stringify(value) {
    return value.getTime()
  },
  parse(value) {
    return new Date(value)
  },
}

const ARRAY = {
  stringify(value) {
    return value.join(',')
  },
  parse(value) {
    return value === '' ? [] : value.split(',')
  },
}

const NUMBER = {
  stringify(value) {
    return String(value)
  },
  parse(value) {
    return parseFloat(value)
  },
}

const BOOLEAN = {
  stringify(value) {
    return value ? '1' : '0'
  },
  parse(value) {
    return value === '1' || value === 1
  },
}
