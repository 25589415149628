import module from '@app/ui/module'
import template from './template.html'

export class AppUiThemeSwitchComponent {
  constructor($document, AppUiThemeService) {
    this.onChange = undefined
    this.container = $document[0].documentElement
    this.appUiThemeService = AppUiThemeService
  }

  get systemScheme() {
    return this.appUiThemeService.systemScheme
  }

  get userScheme() {
    return this.appUiThemeService.userScheme
  }

  get scheme() {
    return this.appUiThemeService.scheme
  }

  $onInit() {
    this.appUiThemeService.addEventListener('change', this.updateView)
    this.updateView()
  }

  $onDestroy() {
    this.appUiThemeService.removeEventListener('change', this.updateView)
  }

  toggle() {
    this.appUiThemeService.toggle()
  }

  updateView = () => {
    this.container.setAttribute('color-scheme', this.scheme)
    this.onChange({ $event: { data: { scheme: this.scheme } } })
  }
}

module.component('appUiThemeSwitch', {
  controller: AppUiThemeSwitchComponent,
  template,
  bindings: {
    onChange: '&',
  },
})
