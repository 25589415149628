import module from '@kendu/ui/module'

module.run(($transitions, $window, KdBreadcrumbs) => {
  const originalTitle = $window.document.title

  $transitions.onSuccess({}, () => {
    KdBreadcrumbs.update().then((title) => {
      $window.document.title = title.length ? `${title.join('\\')} | ${originalTitle}` : originalTitle
    })
  })
})
