import module from 'kendu/legacy/module'
import template from './template.html'
import './style.scss'

export class ComboboxOptionComponent {
  _selected
  value = undefined

  get selected() {
    return this._selected
  }

  set selected(selected) {
    this._selected = selected
    this.$attrs.$set('role', 'option')
    this.$attrs.$set('selected', selected)
  }

  onFocus = () => {
    this.$element.on('keydown', this.onKeydown)
  }

  onBlur = () => {
    this.$element.off('keydown', this.onKeydown)
  }

  onKeydown = (event) => {
    if (event.code === 'Space') {
      this.toggle()
    }
  }

  constructor($element, $attrs, $transclude, $parse) {
    this.$element = $element
    this.$attrs = $attrs
    this.$transclude = $transclude
    this.$parse = $parse
  }

  $onInit() {
    this.$element.on('blur', this.onBlur)
    this.$element.on('focus', this.onFocus)

    this.$attrs.$set('role', 'option')
    this.$attrs.$set('tabindex', '0')
    this._selected = this.$attrs.selected
  }

  toggle() {
    const event = new CustomEvent('click')
    this.$element[0].dispatchEvent(event)
  }
}

module.component('comboboxOption', {
  controller: ComboboxOptionComponent,
  template,
  transclude: true,
  bindings: {
    value: '<',
    selected: '<',
  },
})
