import module from '@admin/usuario/module'
import template from './template.html'

export class AdminUsuarioPermisosModulosItemComponent {
  constructor() {
    this.data = undefined
    this.onChange = undefined
  }

  seleccionar() {
    this.onChange()
  }
}

module.component('adminUsuarioPermisosModulosItem', {
  controller: AdminUsuarioPermisosModulosItemComponent,
  template,
  bindings: {
    data: '<',
    onChange: '&',
  },
})
