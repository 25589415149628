import module from '@kendu/ui/module'

export class KdInputComponent {}

module.directive('kdInput', () => ({
  controller: KdInputComponent,
  restrict: 'A',
  require: {
    kdControl: '^?kdControl',
  },
  link(_scope, _element, attrs, { kdControl }) {
    const placeholder = attrs.placeholder
    attrs.$set('placeholder', '')

    attrs.$addClass('kd-input')
    attrs.$observe('required', (required) => kdControl.setRequired(required))
    attrs.$observe('disabled', (disabled) => kdControl.setDisabled(disabled))

    if (!kdControl) return

    kdControl.setLabel(placeholder ?? '')
  },
}))
