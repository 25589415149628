import module from '@kendu/ui/module'
import './style.scss'

export class KdTableHeadersComponent {
  constructor($element) {
    this.$element = $element
    this.cells = []
  }

  $onChanges(changes) {
    if (changes.columns) {
      this.generateCells()
    }
  }

  generateCells() {
    this.$element.empty()
    this.cells.length = 0

    this.columns.forEach((column) => {
      const scope = {}

      column.getHeader(scope, (clone) => {
        this.cells.push(clone)
        this.$element.append(clone)
      })
    })

    this.$element.css('grid-column-end', this.cells.length)
  }
}

module.component('kdTableHeaders', {
  controller: KdTableHeadersComponent,
  require: {
    kdTable: '^^kdTable',
  },
  bindings: {
    columns: '<',
  },
})
