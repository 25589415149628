import module from '@app/sesion/module'

export class AppUiSystemSchemeService extends EventTarget {
  onChange = () => {
    this.$rootScope.$apply(() => {
      const event = new CustomEvent('change', { detail: { scheme: this.getScheme() } })
      this.dispatchEvent(event)
    })
  }

  constructor($rootScope, $window) {
    super()
    this.$rootScope = $rootScope
    this.darkScheme = $window.matchMedia('(prefers-color-scheme: dark)')
    this.darkScheme.addEventListener('change', this.onChange)
  }

  getScheme() {
    return this.darkScheme.matches ? 'dark' : 'light'
  }

  setScheme() {
    throw new Error(`Unable to modify system color scheme`)
  }
}

module.service('AppUiSystemSchemeService', AppUiSystemSchemeService)
