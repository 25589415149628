import module from '@admin/usuario/module'

export const adminUsuarioPermisosCuentasSchema = {
  activo: 'boolean',
}

export class AdminUsuarioPermisosCuentasService {
  constructor($http) {
    this.$http = $http
  }

  get(idUsuario) {
    return this.$http.get(`api/usuario/${idUsuario}/permisos/cuentas`, {
      schema: adminUsuarioPermisosCuentasSchema,
    })
  }

  put(idUsuario, data) {
    return this.$http.put(`api/usuario/${idUsuario}/permisos/cuentas`, data, {
      schema: adminUsuarioPermisosCuentasSchema,
    })
  }
}

module.service('AdminUsuarioPermisosCuentasService', AdminUsuarioPermisosCuentasService)
