import module from '@app/terminos/module'
import template from './template.html'
import './style.scss'

export class AppUiBreadcrumbsComponent {
  constructor($cacheFactory, AppHttpCache, KdBreadcrumbs) {
    this.$cacheFactory = $cacheFactory
    this.AppHttpCache = AppHttpCache
    this.breadcrumbs = KdBreadcrumbs.breadcrumbs
  }

  clearCache() {
    this.AppHttpCache.destroy()
  }
}

module.component('appUiBreadcrumbs', {
  controller: AppUiBreadcrumbsComponent,
  template,
})
