import module from '@kendu/ui/module'
import { element } from 'angular'

export class KdTableColumnComponent {
  constructor($element, $scope, $transclude) {
    this.element = $element
    this.scope = $scope
    this.transclude = $transclude
    this.width = 'auto'
  }

  $postLink() {
    if (this.element.attr('width')) {
      this.width = this.element.attr('width')
    }

    this.kdTable.columns.add(this.element, this)
  }

  $onDestroy() {
    this.kdTable.columns.remove(this)
  }

  getCell(locals, cloneAttachFn) {
    if (this.transclude.isSlotFilled('cell')) {
      const scope = this.scope.$new()

      Object.assign(scope, locals)

      this.transclude(scope, cloneAttachFn, null, 'cell')
    } else {
      cloneAttachFn(element('<kd-table-cell></kd-table-cell>'))
    }
  }

  getHeader(locals, cloneAttachFn) {
    if (this.transclude.isSlotFilled('header')) {
      const scope = this.scope.$new()

      Object.assign(scope, locals)

      this.transclude(scope, cloneAttachFn, null, 'header')
    } else {
      cloneAttachFn(element('<kd-table-header></kd-table-header>'))
    }
  }
}

module.directive('kdTableColumn', () => ({
  controller: KdTableColumnComponent,
  restrict: 'E',
  require: {
    kdTable: '^^kdTable',
    kdTableColumn: 'kdTableColumn',
  },
  terminal: true,
  bindings: {},
  transclude: {
    cell: '?kdTableCell',
    header: '?kdTableHeader',
  },
  compile() {
    return {
      pre(scope, iElement, iAttrs, ctrl) {
        ctrl.kdTableColumn.kdTable = ctrl.kdTable
      },
    }
  },
}))
