import module from '@kendu/ui/module'

class kdWhileComponent {
  constructor($animate, $attrs, $element, $q, $scope, $transclude) {
    this.$animate = $animate
    this.$attrs = $attrs
    this.$q = $q
    this.$scope = $scope
    this.$transclude = $transclude

    this.block = null
    this.childScope = null
    this.previousElements = null
    this.$element = $element
    this.parentElement = $element.parent()
  }

  $onInit() {
    this.$scope.$watch(this.$attrs.kdAwait, (promise) => {
      this.checkPromise(promise)
    })
  }

  checkPromise(promise) {
    this.create()

    this.$q.when(promise).finally(() => this.destroy())
  }

  create() {
    if (!this.childScope) {
      this.$transclude((clone, newScope) => {
        this.childScope = newScope

        this.block = {
          clone,
        }

        this.$animate.enter(clone, this.parentElement, this.$element)
      })
    }
  }

  destroy() {
    if (this.previousElements) {
      this.previousElements.remove()
      this.previousElements = null
    }

    if (this.childScope) {
      this.childScope.$destroy()
      this.childScope = null
    }

    if (this.block) {
      this.previousElements = this.block.clone

      this.$animate.leave(this.previousElements).done((response) => {
        if (response !== false) this.previousElements = null
      })

      this.block = null
    }
  }
}

module.directive('kdWhile', () => ({
  controller: kdWhileComponent,
  multiElement: true,
  transclude: 'element',
  priority: 700,
  terminal: true,
  restrict: 'A',
  $$tlb: true,
}))
