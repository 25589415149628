import module from '@admin/usuario/module'
import template from './template.html'

export class AdminUsuarioInformacionPersonalComponent {
  constructor(AdminUsuarioInformacionPersonalService) {
    this.idUsuario = undefined
    this.onChange = undefined
    this.onClose = undefined
    this.AdminUsuarioInformacionPersonalService = AdminUsuarioInformacionPersonalService
    this.error = null
    this.procesando = false
  }

  $onInit() {
    this.promise = this.AdminUsuarioInformacionPersonalService.get(this.idUsuario).then(
      (response) => (this.data = response.data),
    )
  }

  aceptar(pristine) {
    if (pristine) {
      this.cerrar()

      return
    }

    if (this.procesando) return

    this.error = null
    this.procesando = true

    return this.AdminUsuarioInformacionPersonalService.put(this.idUsuario, this.data)
      .then((response) => {
        this.onChange({ $event: { data: response.data } })

        this.cerrar()
      })
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }

  cerrar() {
    this.onClose()
  }
}

module.component('adminUsuarioInformacionPersonal', {
  controller: AdminUsuarioInformacionPersonalComponent,
  template,
  bindings: {
    idUsuario: '<',
    onChange: '&',
    onClose: '&',
  },
})
