import module from '@admin/usuario/module'

export const adminUsuarioPermisosEmpresasSchema = {
  activo: 'boolean',
}

export class AdminUsuarioPermisosEmpresasService {
  constructor($http) {
    this.$http = $http
  }

  get(idUsuario) {
    return this.$http.get(`api/usuario/${idUsuario}/permisos/empresas`, {
      schema: adminUsuarioPermisosEmpresasSchema,
    })
  }

  put(idUsuario, data) {
    return this.$http.put(`api/usuario/${idUsuario}/permisos/empresas`, data, {
      schema: adminUsuarioPermisosEmpresasSchema,
    })
  }
}

module.service('AdminUsuarioPermisosEmpresasService', AdminUsuarioPermisosEmpresasService)
