import module from '@admin/usuarios/module'
import template from './template.html'

export class AdminUsuariosAgregarComponent {
  constructor($q, $state, AdminUsuariosExisteService) {
    this.$q = $q
    this.$state = $state
    this.AdminUsuariosExisteService = AdminUsuariosExisteService
    this.confirmar = undefined
    this.catalogos = {
      duracion: [
        { id: 5, descripcion: '5 minutos' },
        { id: 15, descripcion: '15 minutos' },
        { id: 30, descripcion: '30 minutos' },
        { id: 60, descripcion: '1 hora' },
        { id: 240, descripcion: '4 horas' },
        { id: 480, descripcion: '8 horas' },
      ],
    }
    this.data = {
      acceso: {
        alias: '',
        duracion: 60,
        admin: false,
      },
      personal: {
        nombre: '',
        apellidos: '',
        email: '',
      },
    }
  }

  agregar() {
    this.confirmar.open().then((data) => {
      if (data) {
        this.$state.go('usuario', { id_usuario: data.id_usuario.replace('@', '-') })
      }
    })
  }

  cancelar() {
    this.$state.go('usuarios')
  }

  usuarioExiste(alias) {
    let existe = true
    return this.$q((resolve, reject) => {
      this.AdminUsuariosExisteService.get(alias)
        .then((response) => (existe = response.data.existe))
        .finally(() => {
          if (existe) {
            reject()
          } else {
            resolve()
          }
        })
    })
  }
}

module.component('adminUsuariosAgregar', {
  controller: AdminUsuariosAgregarComponent,
  template,
})
