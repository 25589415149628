import module from '@kendu/ui/module'

class KdTransformInterceptor {
  constructor(KdObjectSerializerService) {
    this.KdObjectSerializerService = KdObjectSerializerService
  }

  request = (config) => {
    if (config.data && config.schema) {
      config.data = this.KdObjectSerializerService.serialize(config.data, config.schema)
    }

    return config
  }

  response = (response) => {
    if (response.data && response.config.schema) {
      response.data = this.KdObjectSerializerService.unserialize(response.data, response.config.schema)
    }

    return response
  }
}

module.service('KdTransformInterceptor', KdTransformInterceptor)
