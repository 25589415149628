import module from '@app/ui/module'
import template from './template.html'

export class AppUiComponent {
  constructor($jwt, $localStorage, $q, $state, $session, $scope, $rootScope, KdAuthInterceptor, $cacheFactory) {
    this.$jwt = $jwt
    this.$localStorage = $localStorage
    this.$q = $q
    this.$state = $state
    this.$session = $session
    this.$scope = $scope
    this.$rootScope = $rootScope
    this.$cacheFactory = $cacheFactory
    this.user = null
    this.search = null
    this.loginDialog = undefined
    this.token = this.$jwt.decode(this.$localStorage.token)

    KdAuthInterceptor.setAuthorizer(() => this.authRequerida())
  }

  $onInit() {
    this.$scope.$on('$sessionAuth', () => this.sessionAuth())
    this.$scope.$on('$sessionUnauth', () => this.sessionUnauth())

    if (this.$session.isAuth()) this.sessionAuth()
  }

  authRequerida() {
    if (!this.auth) this.auth = this.$q.defer()

    this.loginDialog.open().then((data) => {
      if (this.auth) {
        if (data) this.auth.resolve()
        else this.auth.reject()
      }

      this.auth = null
    })

    return this.auth.promise
  }

  logout() {
    this.$session.unauth()
  }

  login() {
    this.$state.go('dashboard', {}, { reload: true })
  }

  sessionAuth() {
    this.token = this.$jwt.decode(this.$localStorage.token)
    this.user = undefined
    this.search = true

    if (this.$session.isAuth()) {
      this.$session.current().then((response) => (this.user = response.data))
    }
  }

  sessionUnauth() {
    this.token = null
    this.user = undefined
    this.search = undefined
    this.$cacheFactory.get('$http').removeAll()
    this.$state.go('sesion.terminada', {}, { location: false })
  }
}

module.component('appUi', {
  controller: AppUiComponent,
  template,
})
