import module from 'kendu/legacy/module'

module.directive('development', () => ({
  restrict: 'A',
  transclude: 'element',
  priority: 600,
  terminal: true,
  $$tlb: true,
  link(_$scope, $element, _$attr, _ctrl, $transclude) {
    if (process.env.NODE_ENV !== 'production') {
      $transclude((clone) => $element.replaceWith(clone))
    }
  },
}))
