import module from '@admin/usuario/module'

export const adminUsuarioSchema = {
  inicializado: 'boolean',
}

export class AdminUsuarioService {
  constructor($http) {
    this.$http = $http
  }

  get(idUsuario) {
    return this.$http.get(`api/usuario/${idUsuario}`, {
      schema: adminUsuarioSchema,
    })
  }
}

module.service('AdminUsuarioService', AdminUsuarioService)
