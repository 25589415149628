import module from '@app/excel/module'
import template from './template.html'

export class AppExcelImportarAyudaComponent {
  constructor() {
    this.modal = undefined
  }

  cerrar() {
    this.modal.close()
  }
}

module.component('appExcelImportarAyuda', {
  controller: AppExcelImportarAyudaComponent,
  template,
  bindings: {},
  require: {
    modal: 'kdModal',
  },
})
