import module from '@app/sesion/module'
import template from './template.html'

export class AppSesionRequeridaComponent {
  constructor($state) {
    this.$state = $state
    this.redirigir = undefined
    this.login = undefined
  }

  iniciar() {
    this.login.open().then((data) => {
      if (data) {
        this.autorizado = true

        this.$state.go(this.redirigir.state, this.redirigir.params)
      }
    })
  }
}

module.component('appSesionRequerida', {
  controller: AppSesionRequeridaComponent,
  template,
  bindings: {
    redirigir: '<',
  },
})
