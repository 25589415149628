import module from '@kendu/ui/module'

class KdResizeObserverService {
  observed = new Map()
  observer = null

  onResize = (entries) => {
    for (const entry of entries) {
      this.observed.get(entry.target)?.forEach((fn) => fn(entry))
    }
  }

  observe(element, callback) {
    if (this.observer === null) {
      this.observer = new ResizeObserver(this.onResize)
    }

    let callbacks = this.observed.get(element)

    if (!callbacks) {
      this.observed.set(element, (callbacks = new Set()))
    }

    callbacks.add(callback)
    this.observer.observe(element, { box: 'border-box' })

    return { unobserve: () => this.unobserve(element, callback) }
  }

  unobserve(element, callback) {
    this.observed.get(element)?.delete(callback)

    if (this.observed.size === 0) {
      this.observer.disconnect()
      this.observer = null
    }
  }
}

module.service('KdResizeObserverService', KdResizeObserverService)
