import module from '@admin/usuario/module'

module.config(($stateProvider) => {
  $stateProvider.state('usuario', {
    url: '/admin/usuario/{id_usuario}',
    component: 'adminUsuario',
    data: { title: 'Usuario' },
    redirectTo: 'usuario.informacion',
    resolve: {
      idUsuario: ($transition$) => $transition$.params().id_usuario.replace('-', '@'),
    },
  })
})
