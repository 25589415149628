import angular from 'angular'
import angularTranslate from 'angular-translate'
import appDashboard from './dashboard/index.js'
import appExcel from './excel/index.js'
import appInicio from './inicio/index.js'
import appInterface from './interface/index.js'
import appSesion from './sesion/index.js'
import appTerminos from './terminos/index.js'
import appUi from './ui/index.js'

export default angular.module('app', [
  appDashboard,
  appExcel,
  appInicio,
  appInterface,
  appSesion,
  appTerminos,
  appUi,
  angularTranslate,
])
