import module from '@kendu/ui/module'

class KdStyleDirective{
  constructor($element) {
    this.$element = $element
  }

  $onChanges(changes){
    if(changes.kdStyle){
      const prev = changes.kdStyle.previousValue
      const curr = changes.kdStyle.currentValue

      for (const key of Object.keys(prev)) {
        if(key in curr) continue
        
        this.$element[0].style.removeProperty(key)
      }

      for (const [key, value] of Object.entries(curr)) {
        this.$element[0].style.setProperty(key, value)
      }
    }
  }
}

module.directive('kdStyle', () => ({
  controller: KdStyleDirective,
  restrict: 'A',
  bindToController: {
    kdStyle: '<'
  },
}))
