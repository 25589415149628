import module from '@app/terminos/module'
import template from './template.html'

export class AppTerminosComponent {
  constructor($sce, AppTerminosServicioService) {
    this.$sce = $sce
    this.terminosServicioService = AppTerminosServicioService
  }

  $onInit() {
    this.promise = this.terminosServicioService.get({ lang: this.lang }).then((response) => {
      this.data = this.$sce.trustAsHtml(response.data.contenido)
    })
  }
}

module.component('appTerminos', {
  controller: AppTerminosComponent,
  template,
  bindings: {
    lang: '<',
  },
})
