import module from '@admin/usuarios/module'
import template from './template.html'
import './style.scss'

export class AdminUsuariosListaItemComponent {
  constructor() {
    this.data = undefined
  }

  $onInit() {
    this.idUsuario = this.data.id_usuario.replace('@', '-')
  }
}

module.component('adminUsuariosListaItem', {
  controller: AdminUsuariosListaItemComponent,
  template,
  bindings: {
    data: '<',
  },
})
