import module from '@kendu/ui/module'

class KdErrorInterceptor {
  responseError = (rejection) => {
    if (rejection.headers('Content-Type').includes('application/json')) {
      rejection.data = rejection.data.message
    }

    throw rejection
  }
}

module.service('KdErrorInterceptor', KdErrorInterceptor)
