import module from '@kendu/ui/module'
import template from './template.html'
import './style.scss'

class kdProgressCircularComponent {}

module.component('kdProgressCircular', {
  controller: kdProgressCircularComponent,
  template,
})
