import module from './module.js'
/* Components */
import './components/alarma/component.js'
import './components/alarma/renovar/component.js'
import './components/iniciar/component.js'
import './components/reestablecer/component.js'
import './components/reestablecer/cambiar/component.js'
import './components/registrar/component.js'
import './components/requerida/component.js'
import './components/terminada/component.js'
/* Services */
import './services/Sesion/Registrar.js'
import './services/Sesion/Reestablecer.js'
import './services/Sesion/Reestablecer/Cambiar.js'
/* Routes */
import './routes/sesion.js'
import './routes/sesion/registrar.js'
import './routes/sesion/requerida.js'
import './routes/sesion/reestablecer.js'
import './routes/sesion/reestablecer/cambiar.js'
import './routes/sesion/terminada.js'

export default module.name
