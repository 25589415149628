import { createStyles } from '../functions/style.js'
import { focusElement, allChildrenInert } from '../functions/interaction.js'

export class TopLayer {
  inertObserver = null
  container = null
  children = new Map()

  get size() {
    return this.children.size
  }

  constructor(root) {
    this.root = root
    this.document = this.root.ownerDocument
  }

  add(element, options = { modal: false }) {
    if (this.has(element)) return

    if (this.size === 0) {
      this.createContainer()
    }

    const previousFocusedElement = options.restoreFocus ? this.document.activeElement : null

    const modal = !!options.modal
    element.classList.add(modal ? ':modal' : ':open')

    const wrapper = this.wrapElement(element, modal)
    this.container.append(wrapper)
    this.children.set(element, { wrapper, modal, previousFocusedElement })

    if (!this.inertObserver && this.hasModals()) {
      this.inertObserver = allChildrenInert(this.root, (node) => node === this.container)
    }
  }

  remove(element) {
    if (!this.has(element)) return

    const { wrapper, previousFocusedElement } = this.children.get(element)

    this.children.delete(element)
    wrapper.remove()

    if (this.size === 0) {
      this.removeContainer()
    }

    if (this.inertObserver && !this.hasModals()) {
      this.inertObserver.disconnect()
      this.inertObserver = null
    }

    if (previousFocusedElement) {
      focusElement(previousFocusedElement)
    }
  }

  has(element) {
    return this.children.has(element)
  }

  wrapElement(element, hasBackdrop) {
    const wrapper = this.document.createElement('div')
    wrapper.classList.add('top-layer__wrapper')
    wrapper.append(element)

    if (hasBackdrop) {
      const backdrop = this.document.createElement('div')
      backdrop.classList.add('top-layer__backdrop')
      backdrop.classList.add(':backdrop')
      wrapper.append(backdrop)
    }

    return wrapper
  }

  createContainer() {
    this.container = this.document.createElement('div')
    this.container.classList.add('top-layer')
    this.root.append(this.container)
    this.style = createStyles(this.document, STYLES)
  }

  removeContainer() {
    this.container.remove()
    this.container = null
    this.style.disable()
    this.style = null
  }

  hasModals() {
    return Array.from(this.children.values()).some(({ modal }) => modal)
  }
}

const STYLES = `
.top-layer {
  display: block;
  position: fixed;
  inset: 0;
  z-index: 10;
  contain: content;
  user-select: text;
  pointer-events: auto;
}

.top-layer__wrapper {
  position: fixed;
  inset: 0;
  width: fit-content;
  height: fit-content;
  margin: auto;
  overflow: auto;
}

:where(.\\:modal) {
  display: block;
  position: fixed;
  inset: 0;
  width: fit-content;
  height: fit-content;
  margin: auto;
  overflow: auto;
}

:where(.\\:backdrop) {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.1);
  z-index: -1;
}
`
