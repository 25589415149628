import module from 'kendu/legacy/module'
import template from './template.html'
import './style.scss'

class KdTextfieldComponent {}

module.component('kdTextfield', {
  controller: KdTextfieldComponent,
  template,
  transclude: {
    icon: '?kdIcon',
    prefix: '?kdPrefix',
    suffix: '?kdSuffix',
    actions: '?button',
  },
})
