import module from '@kendu/ui/module'

const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)

module.directive('kdAutofocus', ($timeout) => ({
  restrict: 'A',
  link(scope, element, attrs) {
    attrs.$observe('kdAutofocus', (focused) => {
      switch (focused.toLowerCase()) {
        case 'on':
          focused = true
          break
        case 'off':
          focused = false
          break
        case '':
          focused = !mobile
          break
        default:
          focused = scope.$eval(focused)
      }

      if (focused) {
        $timeout(() => element[0].focus())
      }
    })
  },
}))
