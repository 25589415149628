import module from '@kendu/ui/module'
import './style.scss'

export class KdTableColumnsComponent {
  constructor($element) {
    this.columns = []
    this.element = $element
  }

  add(element, controller) {
    const index = [].findIndex.call(this.element.find('kd-table-column'), (item) => item === element[0])

    if (index >= 0) {
      this.columns.splice(index, 0, controller)
      this.columns = [].concat(this.columns)
    }

    this.setColumns()
  }

  remove(controller) {
    const index = this.columns.indexOf(controller)

    if (index >= 0) {
      this.columns.splice(index, 1)
      this.columns = [].concat(this.columns)
    }

    this.setColumns()
  }

  list() {
    return this.columns
  }

  setColumns() {
    const gridTemplateColumns = this.columns
      .map((column) => column.width + (column.width === 'auto' ? '' : 'px'))
      .join(' ')

    this.kdTable.$element.css('grid-template-columns', gridTemplateColumns)
  }
}

module.directive('kdTableColumns', () => ({
  controller: KdTableColumnsComponent,
  restrict: 'E',
  require: {
    kdTable: '^^kdTable',
    kdTableColumns: 'kdTableColumns',
  },
  link(scope, element, attrs, ctrl, transclude) {
    ctrl.kdTable.columns = ctrl.kdTableColumns
    ctrl.kdTableColumns.kdTable = ctrl.kdTable

    transclude((clone) => element.append(clone), element, 'columns')
  },
}))
