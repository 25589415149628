import module from '@app/ui/module'
import template from './template.html'
import './style.scss'

const originRegexp = /https?:\/\/localhost(?::\d+)?/

export class AppUiProductionComponent {
  productionOrigin = 'https://finanzas.mypb.com.mx/'
  href = ''

  constructor($scope, $window) {
    this.$scope = $scope
    this.$window = $window
  }

  $onInit() {
    this.$scope.$on('$locationChangeSuccess', () => this.setLocation())
    this.setLocation()
  }

  setLocation() {
    this.href = this.$window.location.href.replace(originRegexp, this.productionOrigin)
  }
}

module.component('appUiProduction', {
  controller: AppUiProductionComponent,
  template,
})
