import module from '@app/sesion/module'
import template from './template.html'

export class AppSesionAlarmaRenovarComponent {
  constructor($interval, $session, $timeout, $window) {
    this.$interval = $interval
    this.$session = $session
    this.$timeout = $timeout
    this.$window = $window
    this.procesando = false
    this.error = null
    this.modal = undefined
    this.token = undefined
  }

  $onChanges(changes) {
    if (changes.token && !changes.token.isFirstChange()) {
      this.cerrar()
    }
  }

  $onInit() {
    this.tiempoRestanteTimer = this.$interval(() => this.actualizarTiempoRestante(), 1000)
    this.actualizarTiempoRestante()
    this.beep()
  }

  $onDestroy() {
    this.$interval.cancel(this.tiempoRestanteTimer)
  }

  cerrar() {
    this.modal.close()
  }

  aceptar() {
    if (this.procesando) return

    this.procesando = true
    this.error = null

    this.$session
      .renew()
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }

  actualizarTiempoRestante() {
    const tiempoExpiracion = new Date(this.token.exp * 1000)
    const tiempoActual = new Date()

    this.tiempoRestante = Math.round((tiempoExpiracion.getTime() - tiempoActual.getTime()) / 1000)

    if (!(this.tiempoRestante > 0)) {
      this.cerrar()
    }
  }

  beep() {
    if (!(this.$window.AudioContext || this.$window.webkitAudioContext)) {
      return
    }

    const minFreq = 350
    const maxFreq = 3500
    const duracion = 200
    const intervalos = 3
    const volumen = 0.05

    const audioCtx = new (this.$window.AudioContext || this.$window.webkitAudioContext)()
    const oscillator = audioCtx.createOscillator()
    const gainNode = audioCtx.createGain()

    oscillator.connect(gainNode)
    gainNode.connect(audioCtx.destination)

    gainNode.gain.setValueAtTime(volumen, audioCtx.currentTime)
    oscillator.frequency.setValueAtTime(minFreq, audioCtx.currentTime)
    oscillator.type = 'sine'

    oscillator.start()

    const interval = this.$interval(() => {
      const value = oscillator.frequency.value === maxFreq ? minFreq : maxFreq

      oscillator.frequency.setValueAtTime(value, audioCtx.currentTime)
    }, duracion / intervalos)

    this.$timeout(() => {
      oscillator.stop()

      this.$interval.cancel(interval)
    }, duracion)
  }
}

module.component('appSesionAlarmaRenovar', {
  controller: AppSesionAlarmaRenovarComponent,
  template,
  bindings: {
    token: '<',
  },
  require: {
    modal: 'kdModal',
  },
})
