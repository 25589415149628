import module from '@kendu/ui/module'

let processId = 0

export class KdProcessManagerService {
  processes = new Map()

  createProcess() {
    const id = ++processId
    const manager = this
    const promise = {}
    const process = {
      id,
      resolve: (value) => {
        promise.resolve(value)
        manager.removeProcess(id)
      },
      reject: (value) => {
        promise.reject(value)
        manager.removeProcess(id)
      },
    }

    process.result = new Promise((resolve, reject) => {
      promise.resolve = resolve
      promise.reject = reject
    })

    this.addProcess(process)

    return process
  }

  addProcess(process) {
    this.processes.set(process.id, process)
  }

  getProcess(processId) {
    return this.processes.get(processId)
  }

  removeProcess(processId) {
    this.processes.delete(processId)
  }
}

module.service('KdProcessManagerService', KdProcessManagerService)
