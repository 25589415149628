import angular from 'angular'
import uiRouter from '@uirouter/angularjs'
import angularFilter from 'angular-filter'
import angularTranslate from 'angular-translate'
import angularAnimate from 'angular-animate'
import angularMessages from 'angular-messages'
import angularMessageFormat from 'angular-message-format'

export default angular.module('deprecated', [
  uiRouter,
  angularFilter,
  angularTranslate,
  angularAnimate,
  angularMessages,
  angularMessageFormat,
])
