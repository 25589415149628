import module from '@app/sesion/module'

export class AppDashboardService {
  constructor($http) {
    this.$http = $http
    this.schema = {}
  }

  get() {
    return this.$http.get('api/dashboard', {
      schema: this.schema,
    })
  }
}

module.service('AppDashboardService', AppDashboardService)
