import module from '@kendu/ui/module'

export class KdLoadIndicatorService {
  constructor($q, $rootScope) {
    this.rootScope = $rootScope
    this.pending = 0

    return {
      request: (config) => {
        this.increment()

        return config
      },

      response: (response) => {
        this.decrement()

        return response
      },

      responseError: (rejection) => {
        this.decrement()

        return $q.reject(rejection)
      },
    }
  }

  increment() {
    if (this.pending === 0) {
      this.rootScope.$emit('KdLoadIndicator:start')
    }

    this.pending++
  }

  decrement() {
    this.pending = Math.max(0, this.pending - 1)

    if (this.pending === 0) {
      this.rootScope.$emit('KdLoadIndicator:complete')
    }
  }
}

module.service('KdLoadIndicator', KdLoadIndicatorService)
