import module from '@app/terminos/module'

export class AppTerminosServicioService {
  constructor($http) {
    this.$http = $http
    this.schema = {}
  }

  get(params) {
    return this.$http.get('api/app/terminos_servicio', {
      params,
      schema: this.schema,
    })
  }
}

module.service('AppTerminosServicioService', AppTerminosServicioService)
