import angular from 'angular'
import module from '@kendu/ui/module'
import { TopLayer } from '../classes/top-layer.js'

class KdTopLayerService {
  constructor($document) {
    this.root = angular.element($document[0].body)
    this.topLayer = new TopLayer(this.root[0])
  }

  get size() {
    return this.topLayer.size
  }

  add($element, options) {
    this.topLayer.add($element[0], options)
  }

  remove($element) {
    this.topLayer.remove($element[0])
  }

  has($element) {
    return this.topLayer.has($element[0])
  }
}

module.service('KdTopLayerService', KdTopLayerService)
