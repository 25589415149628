import module from '@admin/usuario/module'

module.config(($stateProvider) => {
  $stateProvider.state('usuario.permisos.proyectos', {
    url: '/proyectos',
    data: { title: 'Proyectos' },
    views: {
      '@usuario': { component: 'adminUsuarioPermisosProyectos' },
    },
  })
})
