import module from '@app/excel/module'
import template from './template.html'

class AppExcelImportarTablaComponent {
  constructor($filter, $window) {
    this.filterBy = $filter('filter')
    this.$window = $window
    this.ayuda = undefined
    this.data = undefined
    this.filtro = undefined
  }

  $onChanges(changes) {
    if (changes.data) {
      this.encabezados = [].concat(this.data.headers)
      this.filas = [].concat(this.data.rows)

      this.filtrar()
    }
  }

  actualizar() {
    this.onChange({
      $event: {
        data: this.filas.length ? { headers: this.encabezados, rows: this.filas } : null,
      },
    })
  }

  /*
   agregarColumna () {
   this.encabezados.push(undefined)
   this.actualizar()
   }

   agregarFila () {
   this.filas.unshift(new Array(this.encabezados.length))
   this.actualizar()
   }
   */

  borrar() {
    this.filas.length = 0

    this.actualizar()
  }

  editar(row, index) {
    const value = this.$window.prompt('Especifique el nuevo valor:', row[index])

    if (value !== null) {
      row[index] = value

      this.actualizar()
    }
  }

  /*
   eliminarFila (fila) {
   this.filas.splice(this.filas.indexOf(fila), 1)
   this.actualizar()
   }
   */

  filtrar() {
    this.resultados = this.filterBy(this.filas, this.filtro)
  }

  mostrarAyuda() {
    this.ayuda.open()
  }

  onChange() {}
}

module.component('appExcelImportarTabla', {
  controller: AppExcelImportarTablaComponent,
  template,
  bindings: {
    data: '<',
    headers: '<',
    onChange: '&',
  },
})
