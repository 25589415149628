import module from '@kendu/ui/module'
import template from './template.html'
import './style.scss'

export class KdSwitchComponent {
  constructor($element) {
    this.onChange = undefined
    this.$element = $element

    this.$element.attr('tabindex', 0)
    this.$element.on('click', () => this.toggle())
  }

  $onChanges(changes) {
    if (changes.value) {
      this.value = !!this.value
      this.render()

      if (!changes.value.isFirstChange()) this.notify()
    }
  }

  set(value = false) {
    this.value = !!value
    this.render()
    this.notify()
  }

  render() {
    this.$element[0].classList.toggle('--checked', this.value)
  }

  toggle() {
    this.set(!this.value)
  }

  notify() {
    this.onChange({ $value: this.value })
  }
}

module.component('kdSwitch', {
  controller: KdSwitchComponent,
  template,
  bindings: {
    value: '<',
    onChange: '&',
  },
})
