import module from '@kendu/ui/module'
import { element } from 'angular'
import './style.scss'

class KdRepeaterComponent {
  constructor($attrs, $element, $filter, $log, $q, $scope, $timeout, $transclude, $window) {
    this.list = undefined
    this.filter = undefined
    this.order = undefined
    this.group = undefined
    this.onComplete = undefined

    this.$attrs = $attrs
    this.$element = $element
    this.$filter = $filter
    this.$log = $log
    this.$q = $q
    this.$scope = $scope
    this.$timeout = $timeout
    this.$transclude = $transclude
    this.$window = $window

    this.check = this.check.bind(this)
    this.filterBy = $filter('filter')
    this.orderBy = $filter('orderBy')
    this.window = element($window)
    this.childScope = $scope.$parent.$new()

    this.threshold = Math.max(0, parseInt($attrs.threshold) || 200)
    this.limit = Math.max(1, parseInt($attrs.limit) || 20)
    this.loaded = []
    this.completed = undefined
    this.timeout = undefined
  }

  $onInit() {
    this.$transclude(this.childScope, (clone) => {
      this.$element.append(clone)

      this.window.on('scroll', this.check)
      this.window.on('resize', this.check)

      this.$scope.$watchGroup(['$ctrl.loaded'], () => this.regenerate())
    })
  }

  $onChanges(changes) {
    if (changes.order) {
      this.order = this.order || ''
    }

    if (changes.group && !(typeof this.group === 'string' || this.group instanceof String)) {
      this.group = ''
    }

    if (changes.list && !Array.isArray(this.list)) {
      this.$log.warn('KdRepeater: Parameter list is not an Array')

      this.list = []
    }

    if (changes.list || changes.filter || changes.order || changes.group) {
      this.pending = this.filterBy(this.orderBy(this.list, this.order), this.filter)
      this.loaded = [].concat(this.pending.splice(0, this.loaded.length))
    }
  }

  $onDestroy() {
    this.window.off('scroll', this.check)
    this.window.off('resize', this.check)

    if (this.timeout) this.$timeout.cancel(this.timeout)
  }

  check() {
    if (this.timeout) this.$timeout.cancel(this.timeout)

    this.timeout = this.$timeout(() => {
      if (!this.pending.length) {
        this.onComplete()

        return
      }

      const windowHeight = this.window[0].innerHeight
      const elementBottom = this.$element[0].offsetTop + this.$element[0].offsetHeight
      const windowBottom = windowHeight + (this.window[0].scrollY || this.window[0].pageYOffset)
      const remaining = elementBottom - windowBottom
      const shouldGetMore = remaining - this.threshold <= 0

      if (shouldGetMore) this.getMore()
    })
  }

  getMore() {
    this.loaded = this.loaded.concat(this.pending.splice(0, this.limit))

    this.check()
  }

  regenerate() {
    const list = [].concat(this.loaded)

    this.childScope.$kdRepeaterItems = list
    this.childScope.$kdRepeaterGroups = this._groupBy(list, this.group)
    this.childScope.$kdRepeaterCompleted = this.pending.length === 0

    this.onChange({
      $event: {
        data: {
          items: this.childScope.$kdRepeaterItems,
          groups: this.childScope.$kdRepeaterGroups,
          completed: this.childScope.$kdRepeaterCompleted,
        },
      },
    })

    this.check()
  }

  _groupBy(array, prop) {
    return array.reduce((object, next) => {
      const group = next[prop]

      if (!object[group]) object[group] = []

      object[group].push(next)

      return object
    }, {})
  }
}

module.component('kdRepeater', {
  controller: KdRepeaterComponent,
  transclude: true,
  bindings: {
    list: '<',
    filter: '<',
    order: '<',
    group: '<',
    onComplete: '&',
    onChange: '&',
  },
})
