import module from '@kendu/ui/module'

export class KdStickComponent {
  constructor($element, KdElementStickService) {
    KdElementStickService.set($element)
  }
}

module.directive('kdStick', () => ({
  controller: KdStickComponent,
  restrict: 'A',
}))
