import module from '@app/excel/module'
import template from './template.html'

export class AppExcelImportarInputComponent {
  constructor() {
    this.data = undefined
    this.onChange = undefined
    this.ayuda = undefined
    this.error = null
    this.incluirEncabezados = true
  }

  aceptar() {
    if (!this.data) return

    this.error = null

    let headersCount = 0

    const rows = this.data.split('\n').map((row) => {
      const cells = row.split('\t')

      headersCount = Math.max(headersCount, cells.length)

      return cells.map((value) => value.trim().replace(/\s\s+/g, ' '))
    })

    const headers = this.incluirEncabezados ? rows.splice(0, 1)[0] : new Array(headersCount)

    if (rows.length) {
      this.onChange({ $event: { data: { rows, headers } } })
    } else {
      this.error = 'No hay suficientes lineas para procesar.'
    }
  }

  borrar() {
    this.data = ''
  }

  mostrarAyuda() {
    this.ayuda.open()
  }
}

module.component('appExcelImportarInput', {
  controller: AppExcelImportarInputComponent,
  template,
  bindings: {
    data: '<',
    onChange: '&',
  },
})
