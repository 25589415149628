import module from './module.js'
/* Components */
import './components/component.js'
import './components/breadcrumbs/component.js'
import './components/production/component.js'
import './components/sync/component.js'
import './components/theme-switch/component.js'
import './components/toolbar/component.js'
/* Services */
import './services/system-scheme.js'
import './services/theme.js'
import './services/user-scheme.js'

export default module.name
