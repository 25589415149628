import module from '@kendu/ui/module'
import './style.scss'

export class kdHoverableComponent {
  onFocus = () => this.$element.on('keypress', this.onKeyPress)

  onBlur = () => this.$element.off('keypress', this.onKeyPress)

  onKeyPress = (event) => {
    if (event.code === 'Space' || event.code === 'Enter') {
      event.target.click()
    }
  }

  constructor($element) {
    this.$element = $element
  }

  $onInit() {
    if (this.$element.attr('tabindex') === undefined) {
      this.$element.attr('tabindex', 0)
    }

    this.$element.on('focus', this.onFocus)
    this.$element.on('blur', this.onBlur)
  }
}

module.directive('kdHoverable', () => ({
  controller: kdHoverableComponent,
  restrict: 'C',
}))
