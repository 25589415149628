import module from '@admin/usuario/module'

module.config(($stateProvider) => {
  $stateProvider.state('usuario.permisos.modulos', {
    url: '/modulos',
    data: { title: 'Módulos' },
    views: {
      '@usuario': { component: 'adminUsuarioPermisosModulos' },
    },
  })
})
