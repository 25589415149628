import module from '@kendu/ui/module'
import { FileResponse } from '../classes/file-response.js'

function getFilename(header) {
  const [, filename] = /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/i.exec(header) ?? []

  return filename ?? ''
}

class KdFileDownloadInterceptor {
  request = (config) => {
    if (config.download) {
      config.responseType = 'blob'
    }

    return config
  }

  response = (response) => {
    if (response.config.download) {
      const filename = getFilename(response.headers('Content-Disposition'))

      response.data = new FileResponse(response.data, filename)
    }

    return response
  }

  responseError = (rejection) => {
    if (!rejection.config.download) {
      return Promise.reject(rejection)
    }

    return new Promise((_resolve, reject) => {
      const reader = new FileReader()

      reader.addEventListener('loadend', (event) => {
        rejection.data = event.target.result ? JSON.parse(event.target.result) : event.target.result
        reject(rejection)
      })

      reader.readAsText(rejection.data)
    })
  }
}

module.service('KdFileDownloadInterceptor', KdFileDownloadInterceptor)
