import module from '@app/terminos/module'

module.config(($stateProvider) => {
  $stateProvider.state('terminos', {
    url: '/app/terminos_servicio?{lang}',
    component: 'appTerminos',
    resolve: {
      lang: ($transition$) => {
        return $transition$.params().lang
      },
    },
  })
})
