import module from 'kendu/legacy/module'

module.directive('uiValidators', () => ({
  restrict: 'A',
  require: 'ngModel',
  link(scope, element, attrs, ngModel) {
    const validators = scope.$eval(attrs.uiValidators)

    Object.entries(validators).forEach(([key, value]) => (ngModel.$validators[key] = value))
  },
}))
