import module from '@admin/usuario/module'

export const adminUsuarioPermisosProyectosSchema = {
  activo: 'boolean',
  visible: 'boolean',
}

export class AdminUsuarioPermisosProyectosService {
  constructor($http) {
    this.$http = $http
  }

  get(idUsuario) {
    return this.$http.get(`api/usuario/${idUsuario}/permisos/proyectos`, {
      schema: adminUsuarioPermisosProyectosSchema,
    })
  }

  put(idUsuario, data) {
    return this.$http.put(`api/usuario/${idUsuario}/permisos/proyectos`, data, {
      schema: adminUsuarioPermisosProyectosSchema,
    })
  }
}

module.service('AdminUsuarioPermisosProyectosService', AdminUsuarioPermisosProyectosService)
