import module from '@admin/usuario/module'
import template from './template.html'

export class AdminUsuarioPermisosModulosComponent {
  constructor($filter, AdminUsuarioPermisosModulosService) {
    this.agrupacion = 'agrupacion'
    this.editando = false
    this.error = null
    this.filtro = ''
    this.resultados = []
    this.seleccionados = []
    this.idUsuario = undefined
    this.onClose = undefined
    this.orderBy = $filter('orderBy')
    this.filterBy = $filter('filter')
    this.AdminUsuarioPermisosModulosService = AdminUsuarioPermisosModulosService
  }

  $onInit() {
    this.promise = this.AdminUsuarioPermisosModulosService.get(this.idUsuario).then((response) => {
      this.data = this.orderBy(this.parse(response.data), [this.agrupacion, 'descripcion'])

      this.editar(false)
    })
  }

  aceptar() {
    if (this.procesando) return

    this.error = null
    this.procesando = true

    const data = this.seleccionados.map((item) => ({ id_modulo: item.id_modulo, id_permiso: item.id_permiso })).sort()

    this.AdminUsuarioPermisosModulosService.put(this.idUsuario, data)
      .then(() => this.onClose())
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }

  editar(editando) {
    this.editando = editando
    this.lista = this.data.filter((item) => (this.editando ? true : item.activo))

    this.filtrar()
  }

  filtrar() {
    const filtro = { ruta: this.filtro }
    const resultados = this.filterBy(this.lista, filtro).reduce((resultados, item) => {
      const permisos = [...item.parents, item.permiso]
      permisos.forEach((item) => {
        if (resultados.indexOf(item) < 0) resultados.push(item)
      })

      return resultados
    }, [])

    this.resultados = this.lista.filter((item) => resultados.indexOf(item.permiso) >= 0)

    this.totalizar()
  }

  parse(data) {
    const ruta = []

    return data.map((item) => {
      item.agrupacion = item.modulo
      item.self = item.permiso.split('.').pop()
      item.children = data
        .filter((e) => e.permiso.indexOf(item.permiso) === 0 && e.permiso !== item.permiso)
        .map((e) => e.permiso)
      item.parents = item.permiso.split('.').reduce((permisos, permiso) => {
        if (permisos.length) permiso = permisos[permisos.length - 1] + '.' + permiso
        if (permiso !== item.permiso) {
          permisos.push(permiso)
          ruta.push(item.descripcion)
        }
        return permisos
      }, [])
      item.nivel = item.parents.length

      ruta.length = item.parents.length
      ruta.push(item.descripcion)

      item.ruta = [item.id_permiso, item.modulo, ...ruta].join('\\')

      return item
    })
  }

  seleccionar(item) {
    item.activo = !item.activo

    const permisos = item.activo ? item.parents : item.children

    permisos.forEach((permiso) => {
      this.data.find((item) => item.permiso === permiso).activo = item.activo
    })

    this.totalizar()
  }

  seleccionarToggle() {
    this.resultados.forEach((item) => (item.activo = !this.todosSeleccionados))

    this.totalizar()
  }

  totalizar() {
    this.todosSeleccionados = this.resultados.every((item) => item.activo)
    this.seleccionados = this.lista.filter((item) => item.activo)
  }
}

module.component('adminUsuarioPermisosModulos', {
  controller: AdminUsuarioPermisosModulosComponent,
  template,
  bindings: {
    idUsuario: '<',
    onClose: '&',
  },
})
