import module from '@kendu/ui/module'
import template from './template.html'
import { focusElement, getFocusableElements } from '@kendu/ui/functions/interaction'
import './style.scss'

export class kdDialogComponent {
  constructor($element) {
    this.$element = $element
  }

  $postLink() {
    this.$element.attr('role', 'dialog')
    this.$element.attr('aria-modal', 'true')
    this.$element.attr('tabindex', '-1')
    this.$element.addClass('kd-dialog')
    this.$element[0].scrollTo(0, 0)
    this.autofocus()
  }

  autofocus() {
    const focusableElements = getFocusableElements(this.$element[0])
    const autofocusElement = focusableElements.find((e) => e.hasAttribute('autofocus'))
    const activeElement = autofocusElement || focusableElements[0] || this.$element[0]
    focusElement(activeElement)
  }
}

module.component('kdDialog', {
  controller: kdDialogComponent,
  template,
  transclude: {
    header: '?kdDialogTitle',
    footer: '?kdDialogActions',
  },
})
