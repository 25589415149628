import module from '@app/sesion/module'
import template from './template.html'

export class AppSesionTerminadaComponent {
  constructor($urlRouter) {
    this.$urlRouter = $urlRouter
  }

  actualizar() {
    this.$urlRouter.sync()
  }
}

module.component('appSesionTerminada', {
  controller: AppSesionTerminadaComponent,
  template,
})
