import module from '@app/sesion/module'

export class AppSesionRegistrarService {
  constructor($http) {
    this.$http = $http
    this.schema = {
      activo: 'boolean',
      acepto_politica_privacidad: 'boolean',
      acepto_terminos_servicio: 'boolean',
    }
  }

  get(token) {
    return this.$http.get(`api/app/sesion/registrar/${token}`, {
      schema: this.schema,
    })
  }

  put(token, data) {
    return this.$http.put(`api/app/sesion/registrar/${token}`, data, {
      schema: this.schema,
    })
  }
}

module.service('AppSesionRegistrarService', AppSesionRegistrarService)
