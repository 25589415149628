import module from '@app/common/module'
import template from './template.html'
import './style.scss'

export class AppLogoComponent {}

module.component('appLogo', {
  controller: AppLogoComponent,
  template,
})
