import module from '@admin/usuario/module'

module.config(($stateProvider) => {
  $stateProvider.state('usuario.permisos.empresas', {
    url: '/empresas',
    data: { title: 'Empresas' },
    views: {
      '@usuario': { component: 'adminUsuarioPermisosEmpresas' },
    },
  })
})
