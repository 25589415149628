import module from '@kendu/ui/module'

export class KdInputAutogrowComponent {
  constructor($attrs, $element, $timeout, $window) {
    this.attrs = $attrs
    this.element = $element
    this.timeout = $timeout
    this.window = $window
  }

  $onInit() {
    if (this.element[0].tagName.toUpperCase() !== 'TEXTAREA') return

    this.textarea = this.element[0]
    this.minRows = parseInt(this.attrs.rows) || 1
    this.maxRows = parseInt(this.attrs.kdInputAutogrow)
    this.setHeight = this.setHeight.bind(this)

    if (!this.minRows || !this.maxRows || this.minRows >= this.maxRows) {
      return
    }

    this.attrs.$set('rows', this.minRows)

    this.textarea.oninput = this.setHeight

    this.timeout(this.setHeight, 0)
  }

  setHeight() {
    const css = this.window.getComputedStyle(this.textarea, null)
    const lineHeight = parseFloat(css.getPropertyValue('line-height'))
    const padding = parseFloat(css.getPropertyValue('padding-top')) + parseFloat(css.getPropertyValue('padding-bottom'))
    const border = parseFloat(css.getPropertyValue('border-top')) + parseFloat(css.getPropertyValue('border-bottom'))
    const minHeight = lineHeight * this.minRows + padding + border
    const maxHeight = lineHeight * this.maxRows + padding + border

    this.textarea.style.height = 0
    this.textarea.style.height = Math.min(maxHeight, Math.max(minHeight, this.textarea.scrollHeight + border)) + 'px'
  }
}

module.directive('kdInputAutogrow', () => ({
  controller: KdInputAutogrowComponent,
  restrict: 'A',
}))
