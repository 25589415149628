import module from '@kendu/ui/module'
import template from './template.html'
import './style.scss'

export class KdControlComponent {
  ngModel = undefined
  label = ''
  required = false
  disabled = false

  constructor($element) {
    this.$element = $element
  }

  $onInit() {
    this.toggleClass('kd-control', true)
    this.$element.attr('init', true)
  }

  setLabel(label) {
    this.label = label
  }

  setRequired(required) {
    this.toggleAttribute('required', required)
    this.toggleClass('kd-control--required', required)
    this.required = required
  }

  setDisabled(disabled) {
    this.toggleAttribute('disabled', disabled)
    this.toggleClass('kd-control--disabled', disabled)
    this.disabled = disabled
  }

  toggleClass(className, value) {
    if (value) {
      this.$element.addClass(className)
    } else {
      this.$element.removeClass(className)
    }
  }

  toggleAttribute(name, value) {
    if (value) {
      this.$element.attr(name, value)
    } else {
      this.$element.removeAttr(name)
    }
  }
}

module.component('kdControl', {
  controller: KdControlComponent,
  template,
  transclude: {
    label: '?label',
    messages: '?ul',
  },
})
