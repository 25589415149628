import module from '@admin/usuario/module'

export const adminUsuarioPermisosSchema = {
  proyectos: {
    total: 'number',
    activos: 'number',
  },
}

export class AdminUsuarioPermisosService {
  constructor($http) {
    this.$http = $http
  }

  get(idUsuario) {
    return this.$http.get(`api/usuario/${idUsuario}/permisos`, {
      schema: adminUsuarioPermisosSchema,
    })
  }
}

module.service('AdminUsuarioPermisosService', AdminUsuarioPermisosService)
