import module from '@admin/usuario/module'
import template from './template.html'

export class AdminUsuarioInformacionComponent {
  constructor(AdminUsuarioInformacionService) {
    this.idUsuario = undefined
    this.AdminUsuarioInformacionService = AdminUsuarioInformacionService
    this.VISTA_PERSONAL = 1
    this.VISTA_CONTACTO = 2

    this.vista = null
  }

  $onInit() {
    this.promise = this.AdminUsuarioInformacionService.get(this.idUsuario).then(
      (response) => (this.data = response.data),
    )
  }

  actualizar(item, data) {
    Object.assign(item, data)
  }

  ver(vista) {
    this.vista = vista || null
  }
}

module.component('adminUsuarioInformacion', {
  controller: AdminUsuarioInformacionComponent,
  template,
  bindings: {
    idUsuario: '<',
  },
})
