import module from './module.js'
/* Components */
import './components/component.js'
import './components/lista/component.js'
import './components/lista/item/component.js'
import './components/agregar/component.js'
import './components/agregar/confirmar/component.js'
/* Services */
import './services/Usuarios.js'
import './services/Usuarios/Existe.js'
/* Routes */
import './routes/usuarios.js'
import './routes/usuarios/agregar.js'

export default module.name
