import module from '@kendu/ui/module'
import './style.scss'

export class KdContentComponent {
  constructor($element) {
    this.$element = $element
  }
}

module.component('kdContent', {
  controller: KdContentComponent,
})
