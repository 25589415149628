import module from '@admin/usuario/module'

module.config(($stateProvider) => {
  $stateProvider.state('usuario.permisos', {
    url: '/permisos',
    component: 'adminUsuarioPermisos',
    data: { title: 'Permisos' },
    resolve: {
      onClose: ($state) => () => $state.go('usuario.permisos'),
    },
  })
})
