import module from '@app/dashboard/module'
import template from './template.html'
import './style.scss'

class AppDashboardLauncherComponent {}

module.component('appDashboardLauncher', {
  controller: AppDashboardLauncherComponent,
  template,
  transclude: {
    icon: '?icon',
    label: '?',
  },
})
