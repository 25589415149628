import module from '@admin/usuarios/module'
import template from './template.html'

export class AdminUsuariosListaComponent {
  constructor() {
    this.data = undefined
    this.filtro = ''
  }

  $onChanges(changes) {
    if (changes.data && Array.isArray(this.data)) {
      this.data.forEach((item) => (item.nombre_completo = item.nombre + ' ' + item.apellido))
    }
  }
}

module.component('adminUsuariosLista', {
  controller: AdminUsuariosListaComponent,
  template,
  bindings: {
    data: '<',
  },
})
