import module from '@app/sesion/module'
import template from './template.html'

export class AppSesionRegistrarActivarComponent {
  constructor(AppSesionRegistrarService) {
    this.token = undefined
    this.data = undefined
    this.modal = undefined
    this.AppSesionRegistrarService = AppSesionRegistrarService
    this.activado = false
  }

  $onInit() {
    this.procesando = true

    const data = {
      acepto_terminos_servicio: this.data.acepto_terminos_servicio,
      password: this.data.password,
    }

    this.AppSesionRegistrarService.put(this.token, data)
      .then(() => (this.activado = true))
      .catch((response) => (this.error = response.data))
      .finally(() => {
        this.procesando = false

        if (!this.error) {
          this.cerrar()
        }
      })
  }

  cerrar() {
    this.modal.close(this.activado)
  }
}

module.component('appSesionRegistrarActivar', {
  controller: AppSesionRegistrarActivarComponent,
  template,
  bindings: {
    token: '<',
    data: '<',
  },
  require: {
    modal: 'kdModal',
  },
})
