import module from '@kendu/ui/module'
import { element } from 'angular'
import template from './KdNotify.template.html'

export function KdNnotifyService($interval, $document, $compile, $rootScope) {
  const per = Math.PI * (8 * 2)
  const defaultDuration = 4
  let container
  let scope
  const notifications = []

  this.info = info
  this.success = success
  this.warn = warn
  this.error = error

  function info(text) {
    return show(text, 'notify-info')
  }

  function success(text) {
    return show(text, 'notify-success')
  }

  function warn(text) {
    return show(text, 'notify-warn')
  }

  function error(text) {
    return show(text, 'notify-error')
  }

  function show(text, type, duration) {
    if (!container) {
      container = element(template)
      scope = $rootScope.$new()
      scope.notifications = notifications
      scope.$closeAll = function () {
        notifications.length = 0
      }

      $compile(container)(scope)
      $document.find('body').eq(0).append(container)
    }

    const notif = new Notification(text, type, duration || defaultDuration)

    notifications.push(notif)

    return notif
  }

  function Notification(text, type, duration) {
    const notification = this

    notification.text = text
    notification.type = type
    notification.timing = per
    notification.colapsed = false
    notification.close = close
    notification.stop = stop

    let elapsed = 0

    const timer = $interval(() => {
      elapsed += 1
      notification.timing = Math.round(per * (1 - elapsed / duration))

      if (elapsed >= duration / 2) {
        notification.colapsed = true
      }

      if (elapsed >= duration) {
        $interval.cancel(timer)
        notification.close()
      }
    }, 1000)

    function close() {
      const i = notifications.indexOf(notification)
      notifications.splice(i, 1)
    }

    function stop() {
      $interval.cancel(timer)
      notification.timing = null
    }
  }
}

module.service('KdNnotify', KdNnotifyService)
