import module from 'kendu/legacy/module'
import template from './template.html'
import './style.scss'

export class ComboboxMenuFilterComponent {
  needle = ''
  onChange = () => {}
  // onKeypress = (event) => this.handleKeypress(event)
  // onKeyup = (event) => this.handleKeyup(event)

  // constructor($document, $scope) {
  //   this.$document = $document
  //   this.$scope = $scope
  // }

  // $onInit() {
  //   this.$document.on('keypress', this.onKeypress)
  //   this.$document.on('keyup', this.onKeyup)
  // }

  // $onDestroy() {
  //   this.$document.off('keypress', this.onKeypress)
  //   this.$document.off('keyup', this.onKeyup)
  // }

  // handleKeypress(event) {
  //   event.preventDefault()
  //   this.needle ??= ''
  //   const newNeedle = ((this.needle ?? '') + event.key).replace(/^ +/, '')
  //   this.$scope.$apply(() => this.filter(newNeedle))
  // }

  // handleKeyup(event) {
  //   event.preventDefault()

  //   if (event.key === 'Backspace') {
  //     const newNeedle = (this.needle ?? '').slice(0, -1)
  //     this.$scope.$apply(() => this.filter(newNeedle))
  //   }
  // }

  filter(newNeedle) {
    // if (newNeedle === this.needle) return
    this.needle = newNeedle
    this.onChange({ $event: { detail: { needle: this.needle } } })
  }
}

module.component('comboboxMenuFilter', {
  controller: ComboboxMenuFilterComponent,
  template,
  bindings: {
    needle: '<',
    onChange: '&',
  },
})
