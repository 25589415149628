import module from 'kendu/legacy/module'
import './style.scss'

export class UiAppViewportComponent {
  constructor($element, $document) {
    this.$element = $element
    this.modals = []
    this.body = $document[0].body
    this.scrollTop = undefined
    this.isCollapsed = false
  }

  setModal(element) {
    this.modals.push(element)
    this.check()
  }

  clearModal(element) {
    this.modals.splice(this.modals.indexOf(element), 1)
    this.check()
  }

  check() {
    if (this.modals.length) {
      this.scrollTop = this.body.scrollTop
      this.$element.addClass('ui-app-viewport-modal')
      this.$element[0].scrollTop = this.scrollTop
    } else {
      this.$element.removeClass('ui-app-viewport-modal')
      this.body.scrollTop = this.scrollTop
    }
  }

  collapse(height) {
    if (this.isCollapsed) {
      this.scrollTop = this.body.scrollTop
      this.$element.addClass('ui-app-viewport-modal')
      this.$element[0].scrollTop = this.scrollTop
    } else {
      this.$element.removeClass('ui-app-viewport-modal')
      this.body.scrollTop = this.scrollTop
    }
  }
}

module.component('uiAppViewport', {
  controller: UiAppViewportComponent,
})
