import module from '@kendu/ui/module'
import './style.scss'

export class KdTableRowComponent {
  constructor($element) {
    this.cells = []
    this.element = $element
    this.index = undefined
    this.data = undefined
    this.columns = undefined
  }

  $onChanges(changes) {
    if (changes.data || changes.columns) {
      this.generateCells()
    }
  }

  generateCells() {
    this.element.empty()
    this.cells.length = 0

    this.columns.forEach((column) => {
      const scope = {
        $row: this.data,
        $rowIndex: this.index,
      }

      column.getCell(scope, (clone) => {
        this.cells.push(clone)

        this.element.append(clone)
      })
    })

    this.element.css('grid-column-end', this.cells.length)
  }
}

module.component('kdTableRow', {
  controller: KdTableRowComponent,
  require: {
    kdTable: '^^kdTable',
  },
  bindings: {
    index: '<',
    data: '<',
    columns: '<',
  },
})
