export function createStyles(document, css) {
  const containerElement = document.head || document.getElementsByTagName('head')[0] || document.body
  const styleElement = document.createElement('style')
  const enable = () => containerElement.appendChild(styleElement)
  const disable = () => styleElement.remove()

  styleElement.type = 'text/css'

  if (styleElement.styleSheet) {
    styleElement.styleSheet.cssText = css
  } else {
    styleElement.appendChild(document.createTextNode(css))
  }

  enable()

  return { enable, disable }
}
