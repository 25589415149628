import module from '@app/dashboard/module'
import template from './template.html'
import './style.scss'

export class AppDashboardComponent {
  constructor(AppDashboardService) {
    this.dashboardService = AppDashboardService
  }

  $onInit() {
    this.promise = this.dashboardService.get().then((response) => (this.data = response.data))
  }
}

module.component('appDashboard', {
  controller: AppDashboardComponent,
  template,
})
