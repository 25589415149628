import module from '@app/sesion/module'
import template from './template.html'

export class AppSesionReestablecerComponent {
  constructor(AppSesionReestablecerService) {
    this.AppSesionReestablecerService = AppSesionReestablecerService
    this.completado = false
    this.error = null
    this.procesando = false
    this.data = {
      email: '',
    }
  }

  reestablecer() {
    if (this.procesando) return

    this.error = null
    this.procesando = true

    this.promise = this.AppSesionReestablecerService.post(this.data)
      .then(() => (this.completado = true))
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }
}

module.component('appSesionReestablecer', {
  controller: AppSesionReestablecerComponent,
  template,
  bindings: {
    token: '<',
  },
})
