import module from '@admin/usuario/module'
import template from './template.html'

export class AdminUsuarioSesionAccesoComponent {
  constructor(AdminUsuarioSesionAccesoService) {
    this.idUsuario = undefined
    this.onChange = undefined
    this.onClose = undefined
    this.AdminUsuarioSesionAccesoService = AdminUsuarioSesionAccesoService
    this.error = null
    this.procesando = false
    this.catalogos = {
      duracion: [
        { id: 5, descripcion: '5 minutos' },
        { id: 15, descripcion: '15 minutos' },
        { id: 30, descripcion: '30 minutos' },
        { id: 60, descripcion: '1 hora' },
        { id: 240, descripcion: '4 horas' },
        { id: 480, descripcion: '8 horas' },
        { id: 720, descripcion: '12 horas' },
      ],
    }
  }

  $onInit() {
    this.promise = this.AdminUsuarioSesionAccesoService.get(this.idUsuario).then(
      (response) => (this.data = response.data),
    )
  }

  aceptar(pristine) {
    if (pristine) {
      this.cerrar()

      return
    }

    if (this.procesando) return

    this.error = null
    this.procesando = true

    return this.AdminUsuarioSesionAccesoService.put(this.idUsuario, this.data)
      .then((response) => {
        this.onChange({ $event: { data: response.data } })

        this.cerrar()
      })
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }

  cerrar() {
    this.onClose()
  }
}

module.component('adminUsuarioSesionAcceso', {
  controller: AdminUsuarioSesionAccesoComponent,
  template,
  bindings: {
    idUsuario: '<',
    onChange: '&',
    onClose: '&',
  },
})
