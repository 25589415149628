import module from '@app/sesion/module'

const now = new Date()

const relativeTimeFormat = new Intl.RelativeTimeFormat('es-MX')

const DATE_UNITS = {
  month: 60 * 60 * 24 * 30,
  day: 60 * 60 * 24,
  hour: 60 * 60,
  minute: 60,
  second: 1,
}

function getSecondsDiff(timestamp) {
  return (Date.now() - timestamp) / 1000
}

function getUnitAndValueDate(secondsElapsed) {
  for (const [unit, secondsInUnit] of Object.entries(DATE_UNITS)) {
    if (secondsElapsed >= secondsInUnit || unit === 'second') {
      const value = Math.floor(secondsElapsed / secondsInUnit) * -1
      return { value, unit }
    }
  }
}

function fromNow(date) {
  const secondsElapsed = getSecondsDiff(date.getTime())
  const { value, unit } = getUnitAndValueDate(secondsElapsed)

  return relativeTimeFormat.format(value, unit)
}

const weekdayFormat = new Intl.DateTimeFormat('es-MX', { weekday: 'long' })

function weekday(date) {
  return weekdayFormat.format(date)
}

function week(date) {
  const oneJan = new Date(date.getFullYear(), 0, 1)
  const numberOfDays = Math.floor((date - oneJan) / (24 * 60 * 60 * 1000))

  return Math.ceil((date.getDay() + 1 + numberOfDays) / 7)
}

function daysInMonth(date) {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
}

const monthGroupFormat = new Intl.DateTimeFormat('es-MX', { month: 'long' })

function monthGroup(date) {
  const year = now.getFullYear()
  const sameYear = year === date.getFullYear()
  const month = monthGroupFormat.format(date)

  return month + (sameYear ? '' : ` ${year}`)
}

function dayMonthGroup(date) {
  const year = date.getFullYear()
  const sameYear = year === now.getFullYear()
  const month = monthGroupFormat.format(date)

  return `${month} ${date.getDate()}` + (sameYear ? '' : `, ${year}`)
}

const weekdayDayGroupFormat = new Intl.DateTimeFormat('es-MX', { weekday: 'long', day: 'numeric' })

function weekdayDayGroup(date) {
  return weekdayDayGroupFormat.format(date)
}

const fullDateFormat = new Intl.DateTimeFormat('es-MX', {
  weekday: 'long',
  day: 'numeric',
  month: 'long',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
})

function fullDate(date) {
  return fullDateFormat.format(date)
}

export class AppTimeService {
  fromNow(date) {
    if (!(date instanceof Date)) date = new Date(date)

    return fromNow(date)
  }

  weekday(date) {
    if (!(date instanceof Date)) date = new Date(date)

    return weekday(date)
  }

  week(date) {
    if (!(date instanceof Date)) date = new Date(date)

    return week(date)
  }

  daysInMonth(date) {
    if (!(date instanceof Date)) date = new Date(date)

    return daysInMonth(date)
  }

  weekdayDayGroup(date) {
    if (!(date instanceof Date)) date = new Date(date)

    return weekdayDayGroup(date)
  }

  dayMonthGroup(date) {
    if (!(date instanceof Date)) date = new Date(date)

    return dayMonthGroup(date)
  }

  monthGroup(date) {
    if (!(date instanceof Date)) date = new Date(date)

    return monthGroup(date)
  }

  fullDate(date) {
    if (!(date instanceof Date)) date = new Date(date)

    return fullDate(date)
  }
}

module.service('AppTimeService', AppTimeService)
