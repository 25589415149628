import module from 'kendu/legacy/module'
import template from './template.html'
import './style.scss'

export class AppToolbarComponent {
  constructor($element, $timeout) {
    this.$element = $element
    this.$timeout = $timeout
  }

  $onInit() {
    this.setHeight()
  }

  setHeight() {
    this.$timeout(() => {
      const content = this.$element.contents()
      const height = content[0].clientHeight

      this.$element.css('height', `${height}px`)
    })
  }
}

module.component('appToolbar', {
  controller: AppToolbarComponent,
  template,
  transclude: true,
})
