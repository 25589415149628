import module from '@app/interface/module'
import './style.scss'

export class AppInterfaceComponent {
  constructor($element, $document) {
    this.$element = $element
    this.views = {}
    this.currentView = ''
    this.body = $document[0].scrollingElement
    this.scrollTop = undefined
    this.element = $element
  }

  setModal(isModal) {
    if (isModal) {
      this.scrollTop = this.body.scrollTop
      this.$element.addClass('modal')
      this.$element[0].scrollTop = this.scrollTop
    } else {
      this.$element.removeClass('modal')
      this.body.scrollTop = this.scrollTop
    }
  }

  setView(name, object) {
    if (object) {
      this.views[name] = object
    } else {
      delete this.views[name]
    }

    this.checkViews()
  }

  checkViews() {
    for (const [name, view] of Object.entries(this.views)) {
      if (name === this.currentView) {
        view.enter()
      } else {
        view.exit()
      }
    }
  }

  view(name) {
    this.currentView = name
    this.checkViews()
  }
}

module.component('appInterface', {
  controller: AppInterfaceComponent,
})
