import module from '@admin/usuario/module'

module.config(($stateProvider) => {
  $stateProvider.state('usuario.sesion.historial', {
    url: '/historial?{periodo:int}&{mes:int}',
    data: { title: 'Historial' },
    resolve: {
      periodo: ($transition$) => $transition$.params().periodo,
      mes: ($transition$) => $transition$.params().mes,
    },
    views: {
      '@usuario': { component: 'adminUsuarioSesionHistorial' },
    },
  })
})
