import module from '@app/sesion/module'

export const appSesionReestablecerCambiarSchema = {
  activo: 'boolean',
}

export class AppSesionReestablecerCambiarService {
  constructor($http) {
    this.$http = $http
  }

  get(token) {
    return this.$http.get(`api/app/sesion/reestablecer/${token}`, {
      schema: appSesionReestablecerCambiarSchema,
    })
  }

  put(token, data) {
    return this.$http.put(`api/app/sesion/reestablecer/${token}`, data, {
      schema: appSesionReestablecerCambiarSchema,
    })
  }
}

module.service('AppSesionReestablecerCambiarService', AppSesionReestablecerCambiarService)
