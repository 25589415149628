import module from '@kendu/ui/module'
import { element } from 'angular'

export class KdBackdropService {
  constructor($animate, $document) {
    this.$animate = $animate
    this.backdrop = element('<div class="kd-backdrop-shadow kd-animate"></div>')
    this.parent = element($document[0].body)
    this.active = false
    this.nodeList = []
    this.animation = null
  }

  show(element) {
    if (this.nodeList.indexOf(element) < 0) {
      this.nodeList.unshift(element)
    }

    this.backdropCheck()
  }

  hide(element) {
    if (this.nodeList.indexOf(element) >= 0) {
      this.nodeList.splice(this.nodeList.indexOf(element), 1)
    }

    element.removeClass('kd-backdrop--active')
    this.backdropCheck()
  }

  backdropCheck() {
    this.nodeList.forEach((element, index) => {
      if (index === 0) {
        element.addClass('kd-backdrop--active')
        element[0].focus()
      } else {
        element.removeClass('kd-backdrop--active')
      }
    })

    if (this.animation) {
      this.$animate.cancel(this.animation)
    }

    if (this.nodeList.length) {
      this.backdropShow()
    } else {
      this.backdropHide()
    }
  }

  backdropShow() {
    if (this.active) return

    this.active = true

    this.animation = this.$animate.enter(this.backdrop, this.parent)
    this.animation.finally(() => (this.animation = null))
  }

  backdropHide() {
    if (!this.active) return

    this.active = false

    this.animation = this.$animate.leave(this.backdrop)
    this.animation.finally(() => (this.animation = null))
  }
}

module.service('KdBackdropService', KdBackdropService)
