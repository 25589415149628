import module from '@kendu/ui/module'
import template from './template.html'
import './style.scss'

export class kdInputFileComponent {
  constructor($element, $fileSystem) {
    this.$element = $element
    this.$fileSystem = $fileSystem
    this.value = undefined
    this.ngModel = undefined
    this.accept = ''
    this.placeholder = ''
  }

  $onInit() {
    this.$element.attr('tabindex', '0')
    this.ngModel.$render = this.render.bind(this)
    this.ngModel.$validators.file = this.validateFile.bind(this)
    this.ngModel.$validators.filetype = this.validateFiletype.bind(this)
  }

  $onChanges(changes) {
    if (changes.accept) {
      try {
        this.acceptRegExp = new RegExp(this.accept.split(',').join('|'))
      } catch (error) {
        this.acceptRegExp = undefined
      }
    }
  }

  render() {
    const value = this.ngModel.$viewValue
    this.name = (value && value.name) || ''
    this.size = (value && value.size && this.formatBytes(value.size)) || ''
  }

  select() {
    this.$fileSystem.select(false, this.accept).then((files) => {
      this.value = files[0]
      this.ngModel.$setViewValue(this.value)
      this.render()
    })
  }

  remove() {
    this.value = undefined
    this.ngModel.$setViewValue(this.value)
    this.render()
  }

  validateFile(value) {
    return value === undefined || value === null || value instanceof File
  }

  validateFiletype(value) {
    return !this.acceptRegExp || !value || !value.type || (this.acceptRegExp && this.acceptRegExp.test(value.type))
  }

  formatBytes(size, decimals = 0) {
    if (size === 0) return '0 Bytes'

    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(size) / Math.log(1024))

    return parseFloat((size / Math.pow(1024, i)).toFixed(decimals)) + ' ' + sizes[i]
  }
}

module.component('kdInputFile', {
  controller: kdInputFileComponent,
  template,
  bindings: {
    accept: '@',
    placeholder: '@',
  },
  require: {
    ngModel: 'ngModel',
  },
})
