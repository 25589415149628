import module from '@app/sesion/module'

module.config(($stateProvider) => {
  $stateProvider.state('sesion.requerida', {
    url: '',
    params: { to: { value: null } },
    data: { title: 'Iniciar sesión' },
    component: 'appSesionRequerida',
    resolve: {
      redirigir: ($transition$) => $transition$.params().to || { state: 'dashboard', params: {} },
    },
  })
})
