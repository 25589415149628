import module from '@app/sesion/module'

function getSchemeValue(value) {
  return ['light', 'dark'].includes(value) ? value : 'auto'
}

export class AppUiUserSchemeService extends EventTarget {
  onChange = () => {
    const event = new CustomEvent('change', { detail: { scheme: this.getScheme() } })
    this.dispatchEvent(event)
  }

  constructor($localStorage, $rootScope) {
    super()
    this.$localStorage = $localStorage
    this.$rootScope = $rootScope

    this.$rootScope.$watch(
      () => this.$localStorage.scheme,
      (current, prev) => current !== prev && this.onChange(),
    )
  }

  getScheme() {
    const userScheme = this.$localStorage.scheme

    return getSchemeValue(userScheme)
  }

  setScheme(value) {
    if (getSchemeValue(value) === 'auto') {
      delete this.$localStorage.scheme
    } else {
      this.$localStorage.scheme = value
    }
  }
}

module.service('AppUiUserSchemeService', AppUiUserSchemeService)
