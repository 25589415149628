import module from '@app/sesion/module'

module.config(($stateProvider) => {
  $stateProvider.state('sesion.reestablecer.cambiar', {
    url: '/{token}',
    data: { title: 'Cambiar' },
    resolve: {
      token: ($transition$) => $transition$.params().token,
    },
    views: {
      '@': {
        component: 'appSesionReestablecerCambiar',
      },
    },
  })
})
