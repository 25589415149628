import module from '@kendu/ui/module'
import scriptContent from './worker.js?raw'

export class KdWorkerService {
  services = new Map()

  constructor(KdProcessManagerService) {
    this.KdProcessManagerService = KdProcessManagerService
  }

  createWorker(scriptURL) {
    let service = this.services.get(scriptURL)

    if (!service) {
      const url = this.getWorkerScript(scriptURL)
      let worker = new Worker(url, { type: 'module' })

      const workerMethods = {
        terminate: () => {
          worker.terminate()
          worker = null
          this.services.delete(scriptURL)
          service.revoke()
        },
      }

      worker.addEventListener('message', ({ data }) => {
        const process = this.KdProcessManagerService.getProcess(data.processId)

        if (data.error) {
          process.reject(data.error)
        } else {
          process.resolve(data.result)
        }
      })

      worker.addEventListener('error', (event) => console.error('error', event))

      service = Proxy.revocable(worker, {
        get: (worker, method) => {
          return (...args) => {
            if (Object.hasOwn(workerMethods, method)) {
              return workerMethods[method](...args)
            }

            const process = this.KdProcessManagerService.createProcess()
            const processId = process.id

            worker.postMessage({ method, args, processId })

            return process.result
          }
        },
      })

      this.services.set(scriptURL, service)
    }

    return service.proxy
  }

  getWorkerScript(scriptURL) {
    const importPath = new URL(scriptURL, document.baseURI)
    const content = scriptContent.replace('IMPORT_MODULE_URL', importPath)

    return URL.createObjectURL(new Blob([content], { type: 'application/javascript' }))
  }
}

module.service('KdWorkerService', KdWorkerService)
