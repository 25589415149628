export function getOffset(element) {
  let x = 0
  let y = 0

  while (element && Number.isFinite(element.offsetLeft) && Number.isFinite(element.offsetTop)) {
    x += element.offsetLeft - element.scrollLeft
    y += element.offsetTop - element.scrollTop
    element = element.offsetParent
  }

  return { x, y }
}
