import module from 'kendu/legacy/module'
import template from './template.html'
import { element } from 'angular'
import './style.scss'

export class ComboboxMenuComponent {
  combobox = undefined
  isOpen = false
  multiple = false
  needle = ''
  options = []
  onClose = () => {}
  onWindowResize = () => requestAnimationFrame(() => this.recalculate())
  onWindowScroll = () => this.close()
  onDocumentClick = (event) => this.handleDocumentClick(event)
  onDocumentKeydown = (event) => this.handleDocumentKeydown(event)

  constructor($attrs, $document, $element, $scope, $timeout, $window) {
    this.$attrs = $attrs
    this.$document = $document
    this.$element = $element
    this.$scope = $scope
    this.$timeout = $timeout
    this.window = element($window)
  }

  $onInit() {
    this.$attrs.$set('tabindex', '-1')
    this.$attrs.$set('role', 'listbox')

    this.window.on('resize', this.onWindowResize)
    this.window.on('scroll', this.onWindowScroll)
    this.$document.on('click', this.onDocumentClick)
    this.$document.on('keydown', this.onDocumentKeydown)

    if (this.multiple) this.$element.on('click', (event) => event.stopPropagation())

    this.combobox.filterOptions()
    this.isOpen = true

    requestAnimationFrame(() => {
      this.recalculate()
      this.scrollSelected()
    })
  }

  $onDestroy() {
    this.window.off('resize', this.onWindowResize)
    this.window.off('scroll', this.onWindowScroll)
    this.$document.off('click', this.onDocumentClick)
    this.$document.off('keydown', this.onDocumentKeydown)
  }

  close() {
    if (!this.isOpen) return

    this.isOpen = false
    this.$element.remove()
    this.onClose()
  }

  handleDocumentClick(event) {
    if (!this.$element[0].contains(event.target)) {
      this.close()
    }

    if (this.multiple) return

    const option = event.target.closest('combobox-option')

    if (option) this.close()
  }

  handleDocumentKeydown(event) {
    if (event.code === 'Escape') {
      this.close()
    }
  }

  recalculate() {
    const margin = 8
    const v = { width: this.window[0].innerWidth, height: this.window[0].innerHeight }
    const c = this.target[0].getBoundingClientRect()
    const e = { width: this.$element[0].scrollWidth, height: this.$element[0].scrollHeight }

    // X
    const leftArea = c.right - margin
    const rightArea = v.width - c.left - margin
    const xDirection = e.width > rightArea && leftArea > rightArea ? 'start' : 'end'
    const xOrigin = xDirection === 'start' ? 'right' : 'left'
    const minWidth = `${c.width}px`
    const maxWidth = xDirection === 'start' ? `${c.left + c.width - margin}px` : `${v.width - c.left - margin}px`
    const left = xDirection === 'start' ? 'auto' : `${c.left}px`
    const right = xDirection === 'start' ? `${v.width - c.right}px` : 'auto'
    this.$element.css('min-width', minWidth)
    this.$element.css('max-width', maxWidth)
    this.$element.css('left', left)
    this.$element.css('right', right)

    // Y
    const topArea = c.bottom - margin
    const bottomArea = v.height - c.top - margin
    const yDirection = e.height > bottomArea && topArea > bottomArea ? 'up' : 'down'
    const yOrigin = yDirection === 'up' ? 'bottom' : 'top'
    const minHeight = `${c.height}px`
    const maxHeight = yDirection === 'up' ? `${c.top + c.height - margin}px` : `${v.height - c.top - margin}px`
    const top = yDirection === 'up' ? 'auto' : `${c.top}px`
    const bottom = yDirection === 'up' ? `${v.height - c.bottom}px` : 'auto'
    this.$element.css('min-height', minHeight)
    this.$element.css('max-height', maxHeight)
    this.$element.css('top', top)
    this.$element.css('bottom', bottom)

    this.$element.css('transform-origin', xOrigin + ' ' + yOrigin)
    this.$element.attr('direction', xDirection + ' ' + yDirection)
  }

  scrollSelected() {
    const selectedElement = this.$element[0].querySelector('combobox-option[selected]')

    if (!selectedElement) return

    const [, direction] = this.$element.attr('direction').split(' ')
    const headerElement = direction === 'down' ? this.$element[0].querySelector('.combobox-menu__head') : null

    this.$element[0].scrollTop = selectedElement.offsetTop - (headerElement?.offsetHeight ?? 0)
  }

  filter(needle) {
    this.needle = needle
    this.combobox.filterOptions(this.needle)
  }

  selectAll(selected) {
    this.combobox.toggleItems(selected)
  }
}

module.component('comboboxMenu', {
  controller: ComboboxMenuComponent,
  template,
  transclude: true,
  bindings: {
    target: '<',
    multiple: '<',
    options: '<',
    combobox: '<',
    onClose: '&',
  },
})
