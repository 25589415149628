import module from '@admin/usuario/module'
import template from './template.html'

export class AdminUsuarioPermisosCuentasComponent {
  constructor($filter, AdminUsuarioPermisosCuentasService) {
    this.agrupacion = 'agrupacion'
    this.editando = false
    this.error = null
    this.filtro = ''
    this.resultados = []
    this.seleccionados = []
    this.filterBy = $filter('filter')
    this.orderBy = $filter('orderBy')
    this.AdminUsuarioPermisosCuentasService = AdminUsuarioPermisosCuentasService
    this.idUsuario = undefined
    this.onClose = undefined
  }

  $onInit() {
    this.promise = this.AdminUsuarioPermisosCuentasService.get(this.idUsuario).then((response) => {
      const data = response.data.map((item) => {
        item.agrupacion = item.empresa

        return item
      })

      this.data = this.orderBy(data, [this.agrupacion, 'descripcion'])

      this.editar(false)
    })
  }

  aceptar() {
    if (this.procesando) return

    this.error = null
    this.procesando = true

    const data = this.seleccionados
      .map((item) => ({
        id_empresa: item.id_empresa,
        id_cuenta: item.id_cuenta,
        kardex_id_empleado: item.kardex_id_empleado,
      }))
      .sort()

    this.AdminUsuarioPermisosCuentasService.put(this.idUsuario, data)
      .then(() => this.onClose())
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }

  editar(editando) {
    this.editando = editando
    this.lista = this.data.filter((item) => (this.editando ? true : item.activo))

    this.filtrar()
  }

  filtrar() {
    this.resultados = this.filterBy(this.lista, this.filtro)

    this.totalizar()
  }

  seleccionar(item) {
    item.activo = !item.activo

    this.totalizar()
  }

  seleccionarToggle() {
    this.resultados.forEach((item) => (item.activo = !this.todosSeleccionados))

    this.totalizar()
  }

  totalizar() {
    this.todosSeleccionados = this.resultados.every((item) => item.activo)
    this.seleccionados = this.lista.filter((item) => item.activo)
  }
}

module.component('adminUsuarioPermisosCuentas', {
  controller: AdminUsuarioPermisosCuentasComponent,
  template,
  bindings: {
    idUsuario: '<',
    onClose: '&',
  },
})
