import module from '@admin/usuario/module'
import template from './template.html'
import orderBy from 'lodash-es/orderBy.js'

export class AdminUsuarioPermisosClientesComponent {
  constructor($filter, AdminUsuarioPermisosClientesService) {
    this.idUsuario = undefined
    this.onClose = undefined
    this.AdminUsuarioPermisosClientesService = AdminUsuarioPermisosClientesService
    this.filterBy = $filter('filter')
    this.agrupacion = 'agrupacion'
    this.editando = false
    this.error = null
    this.filtro = ''
    this.resultados = []
    this.seleccionados = []
  }

  $onInit() {
    this.promise = this.AdminUsuarioPermisosClientesService.get(this.idUsuario).then((response) => {
      this.data = orderBy(
        response.data.map((item) => {
          item.agrupacion = item.descripcion.substring(0, 1)

          return item
        }),
        'descripcion',
      )

      this.editar(false)
    })
  }

  aceptar() {
    if (this.procesando) return

    this.error = null
    this.procesando = true

    const data = this.seleccionados.map((item) => item.id_cliente).sort()

    this.AdminUsuarioPermisosClientesService.put(this.idUsuario, data)
      .then(() => this.onClose())
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }

  editar(editando) {
    this.editando = editando
    this.lista = this.data.filter((item) => (this.editando ? true : item.activo))

    this.filtrar()
  }

  filtrar() {
    this.resultados = this.filterBy(this.lista, this.filtro)

    this.totalizar()
  }

  seleccionar(item) {
    item.activo = !item.activo

    this.totalizar()
  }

  seleccionarToggle() {
    this.resultados.forEach((item) => (item.activo = !this.todosSeleccionados))

    this.totalizar()
  }

  totalizar() {
    this.todosSeleccionados = this.resultados.every((item) => item.activo)
    this.seleccionados = this.lista.filter((item) => item.activo)
  }
}

module.component('adminUsuarioPermisosClientes', {
  controller: AdminUsuarioPermisosClientesComponent,
  template,
  bindings: {
    idUsuario: '<',
    onClose: '&',
  },
})
