import module from '@admin/usuario/module'

export const adminUsuarioInformacionPersonalSchema = {}

export class AdminUsuarioInformacionPersonalService {
  constructor($http) {
    this.$http = $http
  }

  get(idUsuario) {
    return this.$http.get(`api/usuario/${idUsuario}/informacion/personal`, {
      schema: adminUsuarioInformacionPersonalSchema,
    })
  }

  put(idUsuario, data) {
    return this.$http.put(`api/usuario/${idUsuario}/informacion/personal`, data, {
      schema: adminUsuarioInformacionPersonalSchema,
    })
  }
}

module.service('AdminUsuarioInformacionPersonalService', AdminUsuarioInformacionPersonalService)
