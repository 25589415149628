import module from './module.js'
/* Config */
import './config.js'
/* Run */
import './run.js'
/* Styles */
import './styles/--cursor/style.scss'
import './styles/--disabled/style.scss'
import './styles/--font/style.scss'
import './styles/--margin/style.scss'
import './styles/--opacity/style.scss'
import './styles/--sr-only/style.scss'
import './styles/--text/style.scss'
import './styles/kd-animate-height/directive.js'
import './styles/kd-badge/style.scss'
import './styles/kd-blackboard/style.scss'
import './styles/kd-button/style.scss'
import './styles/kd-dialog/style.scss'
import './styles/kd-fieldset/style.scss'
import './styles/kd-hoverable/directive.js'
import './styles/kd-icon/style.scss'
import './styles/kd-id/style.scss'
import './styles/kd-input/style.scss'
import './styles/kd-item/style.scss'
import './styles/kd-link/style.scss'
import './styles/kd-menu/style.scss'
import './styles/kd-sticky/style.scss'
import './styles/kd-tabs/style.scss'
import './styles/kd-toolbar/style.scss'
/* Components */
import './components/kd-alert/component.js'
import './components/kd-checkbox/component.js'
import './components/kd-content/component.js'
import './components/kd-control/component.js'
import './components/kd-dialog/component.js'
import './components/kd-dialog-actions/component.js'
import './components/kd-dialog-title/component.js'
import './components/kd-textfield/component.js'
import './components/kd-input-file/component.js'
import './components/kd-load-indicator/component.js'
import './components/kd-loader/component.js'
import './components/kd-progress/component.js'
import './components/kd-progress-circular/component.js'
import './components/kd-repeater/component.js'
import './components/kd-switch/component.js'
import './components/kd-table/component.js'
import './components/kd-topbar/component.js'
/* Directives */
import './directives/kd-autofocus/directive.js'
import './directives/kd-await/directive.js'
import './directives/kd-backdrop/directive.js'
import './directives/kd-fill-available/directive.js'
import './directives/kd-input/directive.js'
import './directives/kd-input-autogrow/directive.js'
import './directives/kd-modal/directive.js'
import './directives/kd-sortable/directive.js'
import './directives/kd-stick/directive.js'
import './directives/kd-style/directive.js'
import './directives/kd-while/directive.js'
/* Services */
import './services/KdBackdrop.js'
import './services/KdBreadcrumbs.js'
import './services/KdElementScrollClip.js'
import './services/KdElementStick.js'
import './services/KdLoadIndicator.js'
import './services/KdModal.js'
import './services/KdNotify.js'
import './services/kdObjectSerializer.js'
import './services/KdResizeObserver.js'
import './services/KdTaskRunner.js'
import './services/KdTopLayer.js'
/* Interceptors */
import './interceptors/kd-auth.interceptor.js'
import './interceptors/kd-cache.interceptor.js'
import './interceptors/kd-error.interceptor.js'
import './interceptors/kd-file-download.interceptor.js'
import './interceptors/kd-form-data.interceptor.js'
import './interceptors/kd-transform.interceptor.js'

export default module.name
