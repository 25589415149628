import module from '@kendu/ui/module'
import './style.scss'

export class kdLoaderComponent {
  constructor($animate, $attrs, $compile, $element, $q) {
    this.kdPromise = undefined
    this.comment = $compile.$$createComment('kdLoader', $attrs.kdPromise)
    this.animate = $animate
    this.element = $element
    this.parent = this.element.parent()
    this.q = $q

    this.element.replaceWith(this.comment)
  }

  $onChanges(changes) {
    if (changes.kdPromise) {
      this.checkPromise()
    }
  }

  checkPromise() {
    this.animate.enter(this.element, this.parent, this.comment)

    this.q.when(this.kdPromise).finally(() => this.animate.leave(this.element))
  }
}

module.directive('kdLoader', {
  controller: kdLoaderComponent,
  bindings: {
    kdPromise: '<',
  },
})
