import module from '@kendu/ui/module'
import './style.scss'

module.animation('.kd-animate-height', ($animateCss) => ({
  enter(element) {
    const box = element[0].getBoundingClientRect()
    const height = box.height

    return $animateCss(element, {
      event: 'enter',
      structural: true,
      easing: 'ease-in',
      from: {
        height: '0px',
        transform: 'scaleY(0)',
        opacity: 0,
      },
      to: {
        height: `${height}px`,
        transform: 'scaleY(1)',
        opacity: 1,
      },
      duration: 0.15,
      cleanupStyles: true,
    })
  },
}))
