import module from '@kendu/ui/module'

export class KdElementStickServiceService {
  constructor() {
    this.nodes = []
  }

  set(element) {
    if (this.nodes.indexOf(element) >= 0) return

    element.addClass('kd-sticky')

    element.on('$destroy', () => {
      this.nodes.splice(this.nodes.indexOf(element), 1)

      this.calculate()
    })

    this.nodes.push(element)

    this.calculate()
  }

  calculate() {
    let top = 0

    this.nodes.forEach((element) => {
      const rect = element[0].getBoundingClientRect()
      const height = rect.height

      element.css('top', top + 'px')

      top += height
    })
  }
}

module.service('KdElementStickService', KdElementStickServiceService)
