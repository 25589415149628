import Sortable from 'sortablejs'
import module from '@kendu/ui/module'
import angular from 'angular'

const expando = 'Sortable:kd-sortable'

const watchOptionNames = [
  'sort',
  'disabled',
  'draggable',
  'handle',
  'animation',
  'group',
  'ghostClass',
  'filter',
  'onStart',
  'onEnd',
  'onAdd',
  'onUpdate',
  'onRemove',
  'onSort',
  'onMove',
  'onClone',
  'setData',
  'delay',
  'animation',
  'forceFallback',
]

module.constant('kdSortableConfig', {})

class KdSortable {
  /**
   * @param {angular.IScope} $scope
   * @param {JQLite} $element
   * @param {angular.IParseService} $parse
   * @param {Sortable.Options} kdSortableConfig
   */
  constructor($scope, $element, $parse, kdSortableConfig) {
    this.$scope = $scope
    this.$parse = $parse
    this.kdSortableConfig = kdSortableConfig
    this.element = $element[0]
    /** @type {string} */
    this.itemsExpr = ''
    /** @type {ChildNode | null} */
    this.nextSibling = null
    /** @type {ChildNode | null} */
    this.removed = null
    /** @type {Array<() => void> | null} */
    this.watchers = null
    /** @type {Sortable.Options} */
    this.config = {}
    /** @type {Sortable | null} */
    this.sortable = null
    /** @type {() => Array<unknown>} */
    this.getSource = () => []
  }

  $postLink() {
    if (!this.itemsExpr) return

    const parser = this.$parse(this.itemsExpr)
    this.getSource = () => parser(this.$scope.$parent) ?? []

    this.element[expando] = this.getSource

    this.config = { ...this.$scope.kdSortable, ...this.kdSortableConfig }

    this.sortable = Sortable.create(this.element, {
      ...this.config,
      onStart: (event) => this.$scope.$apply(() => this.onStart(event)),
      onEnd: (event) => this.$scope.$apply(() => this.onEnd(event)),
      onAdd: (event) => this.$scope.$apply(() => this.onAdd(event)),
      onUpdate: (event) => this.$scope.$apply(() => this.onUpdate(event)),
      onRemove: (event) => this.$scope.$apply(() => this.onRemove(event)),
      onSort: (event) => this.$scope.$apply(() => this.onSort(event)),
    })

    this.watchers = watchOptionNames.map((name) =>
      this.$scope.$watch(`kdSortable.${name}`, (value) => {
        if (typeof value === 'undefined') return
        this.config[name] = value
        if (!/^on[A-Z]/.test(name)) this.sortable.option(name, value)
      }),
    )
  }

  /**
   *
   * @param {Sortable.SortableEvent} event
   * @param {*} item
   */
  emitEvent(event, item) {
    /** @type {'onStart' | 'onEnd' | 'onAdd' | 'onUpdate' | 'onRemove' | 'onSort'} */
    const handlerName = 'on' + event.type.charAt(0).toUpperCase() + event.type.substring(1)
    const handler = this.config[handlerName]

    if (handler) {
      const source = this.getSource()
      handler({
        model: item ?? source[event.newIndex],
        models: source,
        oldIndex: event.oldIndex,
        newIndex: event.newIndex,
        originalEvent: event,
      })
    }
  }

  /** @param {Sortable.SortableEvent} event */
  sync(event) {
    const items = this.getSource()

    if (!items) return

    const oldIndex = event.oldIndex
    const newIndex = event.newIndex

    if (this.element === event.from) {
      items.splice(newIndex, 0, items.splice(oldIndex, 1)[0])

      if (this.nextSibling.nodeType === Node.COMMENT_NODE) {
        event.from.insertBefore(this.nextSibling, event.item.nextSibling)
      }
    } else {
      const prevItems = event.from[expando]()

      this.removed = prevItems[oldIndex]

      if (Sortable.active && Sortable.active.lastPullMode === 'clone') {
        this.removed = angular.copy(this.removed)
        const index = Sortable.utils.index(event.clone, this.sortable.options.draggable)
        prevItems.splice(index, 0, prevItems.splice(oldIndex, 1)[0])

        if (event.from.contains(event.clone)) event.from.removeChild(event.clone)
      } else {
        prevItems.splice(oldIndex, 1)
      }

      items.splice(newIndex, 0, this.removed)

      event.from.insertBefore(event.item, this.nextSibling)
    }
  }

  /** @param {Sortable.SortableEvent} event */
  onStart(event) {
    this.nextSibling = event.from === event.item.parentNode ? event.item.nextSibling : event.clone.nextSibling
    this.emitEvent(event)
  }

  /** @param {Sortable.SortableEvent} event */
  onEnd(event) {
    this.emitEvent(event, this.removed)
  }

  /** @param {Sortable.SortableEvent} event */
  onAdd(event) {
    this.sync(event)
    this.emitEvent(event, this.removed)
  }

  /** @param {Sortable.SortableEvent} event */
  onUpdate(event) {
    this.sync(event)
    this.emitEvent(event)
  }

  /** @param {Sortable.SortableEvent} event */
  onRemove(event) {
    this.emitEvent(event, this.removed)
  }

  /** @param {Sortable.SortableEvent} event */
  onSort(event) {
    this.emitEvent(event)
  }

  $onDestroy() {
    this.watchers?.forEach((unwatch) => unwatch())
    this.sortable?.destroy()
  }
}

module.directive('kdSortable', () => ({
  controller: KdSortable,
  restrict: 'AC',
  scope: { kdSortable: '=?' },
  priority: 1001,
  compile: (tElement) => {
    /** @type {(element: Element) => string | null} */
    const getNgRepeatExpression = (element) =>
      element.getAttribute('ng-repeat') ||
      element.getAttribute('data-ng-repeat') ||
      element.getAttribute('x-ng-repeat') ||
      element.getAttribute('dir-paginate')

    const ngRepeatRegExp = /^\s*([\s\S]+?)\s+in\s+([\s\S]+?)(?:\s+as\s+([\s\S]+?))?(?:\s+track\s+by\s+([\s\S]+?))?\s*$/
    /** @type {(expression: string) => RegExpMatchArray | null} */
    const parseNgRepeatExpression = (expression) => expression.match(ngRepeatRegExp)

    const nodes = Array.from(tElement[0].childNodes)
    const element = nodes.find((node) => node.nodeType === Node.ELEMENT_NODE && getNgRepeatExpression(node))

    if (!element) return

    const match = parseNgRepeatExpression(getNgRepeatExpression(element))

    if (!match) return

    /** @param {KdSortable} controller */
    return function postLink(scope, iElement, iAttrs, controller) {
      controller.itemsExpr = match[2]
    }
  },
}))
