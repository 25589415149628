import module from 'kendu/legacy/module'

module.directive('currency', ($filter) => {
  const transform = $filter('currency')

  return {
    restrict: 'A',
    require: 'ngModel',
    link(scope, element, attrs, ngModel) {
      const roundTo = Number(attrs.currency) || 2
      const input = element[0]

      const render = () => {
        const value = ngModel.$modelValue

        if (input === document.activeElement) {
          input.type = 'number'
          input.value = ngModel.$isEmpty(value) ? value : value.toFixed(roundTo)
        } else {
          input.type = 'text'
          input.value = ngModel.$isEmpty(value) ? '' : transform(value, '$', roundTo)
        }
      }

      element.bind('focus', render)
      element.bind('blur', render)
      ngModel.$render = render
    },
  }
})
