import module from '@kendu/ui/module'

class KdAuthInterceptor {
  authorize = (v) => v

  constructor($injector, $localStorage) {
    this.$injector = $injector
    this.$localStorage = $localStorage
  }

  request = (config) => {
    if (this.$localStorage.token) {
      config.headers ??= {}
      config.headers.Authorization = `Bearer ${this.$localStorage.token}`
    }

    return config
  }

  responseError = async (rejection) => {
    if (rejection.status !== 401) throw rejection

    try {
      await Promise.resolve(this.authorize(rejection))
      return this.$injector.get('$http')(rejection.config)
    } catch {
      throw rejection
    }
  }

  setAuthorizer(authorize) {
    this.authorize = authorize
  }
}

module.service('KdAuthInterceptor', KdAuthInterceptor)
