import module from '@app/module'

module.config(
  (
    $urlRouterProvider,
    $stateProvider,
    $animateProvider,
    $translateProvider,
    $locationProvider,
    $httpProvider,
    $compileProvider,
  ) => {
    $urlRouterProvider.otherwise('/')

    $animateProvider.classNameFilter(/kd-animate/)

    $locationProvider.html5Mode(true)

    $httpProvider.useApplyAsync(true)

    $httpProvider.interceptors.push('KdLoadIndicator')

    $compileProvider.debugInfoEnabled(window.location.hostname === 'localhost')
  },
)
