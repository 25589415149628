import module from '@admin/usuarios/module'

export const AdminUsuariosSchema = {
  admin: 'boolean',
  inicializado: 'boolean',
  acceso: {
    admin: 'boolean',
  },
}

export class AdminUsuariosService {
  constructor($http) {
    this.$http = $http
  }

  get() {
    return this.$http.get('api/usuarios', {
      schema: AdminUsuariosSchema,
    })
  }

  post(data) {
    return this.$http.post('api/usuarios', data, {
      schema: AdminUsuariosSchema,
    })
  }
}

module.service('AdminUsuariosService', AdminUsuariosService)
