import module from '@kendu/ui/module'
import { getOffset } from '../../functions/element-position.js'

export class KdFillAvailableDirective {
  resize = () => {
    const totalHeight = this.$window.innerHeight
    const { y } = getOffset(this.$element[0])

    this.$element.css('height', `${totalHeight - y}px`)
  }

  constructor($element, $timeout, $window) {
    this.$element = $element
    this.$timeout = $timeout
    this.$window = $window
  }

  $postLink() {
    this.$window.addEventListener('resize', this.resize)
    this.$timeout(this.resize)
  }

  $onDestroy() {
    this.$window.removeEventListener('resize', this.resize)
  }
}

module.directive('kdFillAvailable', () => ({
  controller: KdFillAvailableDirective,
  restrict: 'A',
}))
