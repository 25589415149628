import module from '@app/sesion/module'
import template from './template.html'
import './style.scss'

class AppSesionIniciarComponent {
  constructor($session) {
    this.$session = $session
    this.error = null
    this.credencial = { username: '', password: '' }
    this.procesando = false
    this.respuesta = undefined
    this.modal = undefined
  }

  cerrar() {
    this.modal.close(this.respuesta)
  }

  aceptar() {
    if (this.procesando) return

    this.error = null
    this.procesando = true

    return this.$session
      .auth(this.credencial)
      .then((response) => {
        this.respuesta = response.data
        this.cerrar()
      })
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }
}

module.component('appSesionIniciar', {
  controller: AppSesionIniciarComponent,
  template,
  bindings: {},
  require: {
    modal: 'kdModal',
  },
})
