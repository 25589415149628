import module from '@app/sesion/module'
import template from './template.html'

export class AppSesionReestablecerCambiarComponent {
  constructor(AppSesionReestablecerCambiarService) {
    this.AppSesionReestablecerCambiarService = AppSesionReestablecerCambiarService
    this.completado = false
    this.token = undefined
    this.ayuda = undefined
    this.error = null
    this.procesando = false
    this.validadorPassword = {
      strength: (value) => {
        this.password_strength = this.scorePassword(value)

        return this.password_strength >= 50
      },
    }
    this.validadorConfirmarPassword = {
      passmatch: (value) => this.data.password === value,
    }
  }

  $onInit() {
    this.promise = this.AppSesionReestablecerCambiarService.get(this.token).then(
      (response) => (this.data = response.data),
    )
  }

  cambiar() {
    if (this.procesando) return

    this.procesando = true
    this.error = null

    this.AppSesionReestablecerCambiarService.put(this.token, this.data)
      .then((response) => (this.completado = response.data.completado))
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }

  mostrarAyuda() {
    this.ayuda.open()
  }

  scorePassword(pass) {
    let score = 0

    if (!pass) return score

    const letters = {}

    for (let i = 0; i < pass.length; i++) {
      letters[pass[i]] = (letters[pass[i]] || 0) + 1
      score += 5.0 / letters[pass[i]]
    }

    const variations = {
      digits: /\d/.test(pass),
      lower: /[a-z]/.test(pass),
      upper: /[A-Z]/.test(pass),
      nonWords: /\W/.test(pass),
    }

    let variationCount = 0

    for (const variation of Object.values(variations)) {
      variationCount += variation ? 1 : 0
    }

    score += (variationCount - 1) * 10

    return Math.min(score, 100)
  }
}

module.component('appSesionReestablecerCambiar', {
  controller: AppSesionReestablecerCambiarComponent,
  template,
  bindings: {
    token: '<',
  },
})
