import module from '@app/module'

module.run(($rootScope, $localStorage, AppHttpCache) => {
  $rootScope.$watch(
    () => $localStorage.token,
    (token, previousToken) => {
      if (token === previousToken) return

      AppHttpCache.destroy()

      if (token) $rootScope.$broadcast('$sessionAuth')
      else $rootScope.$broadcast('$sessionUnauth')
    },
  )
})
