import module from 'kendu/legacy/module'

export class AppHttpCache {
  constructor($cacheFactory) {
    this.$cacheFactory = $cacheFactory
    this.caches = {}
  }

  get(name) {
    if (!this.caches[name]) {
      this.caches[name] = this.$cacheFactory(name)
    }

    return this.caches[name]
  }

  clear(name) {
    if (this.caches[name]) {
      this.caches[name].removeAll()
    }
  }

  destroy() {
    this.$cacheFactory.get('$http').removeAll()

    for (const cache of Object.values(this.caches)) {
      cache.removeAll()
    }
  }
}

module.service('AppHttpCache', AppHttpCache)
