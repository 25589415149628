import module from '@admin/usuarios/module'
import template from './template.html'

export class AdminUsuariosComponent {
  constructor(AdminUsuariosService) {
    this.AdminUsuariosService = AdminUsuariosService
    this.data = null
  }

  $onInit() {
    this.promise = this.AdminUsuariosService.get().then((response) => (this.data = response.data))
  }
}

module.component('adminUsuarios', {
  controller: AdminUsuariosComponent,
  template,
})
