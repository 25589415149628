import module from 'kendu/legacy/module'
import template from './template.html'
import { equals } from 'angular'
import './style.scss'
import './menu/component.js'
import './menu-filter/component.js'
import './option/component.js'

const comboboxMenuTemplate = `
<combobox-menu
  options="$ctrl.options"
  multiple="$ctrl.multiple"
  target="$ctrl.$element"
  on-close="$ctrl.toggleMenu(false);ctrl.setView()"
  combobox="$ctrl">
  <combobox-option
    ng-if="!$ctrl.multiple && !$ctrl.required"
    selected="$ctrl.isEmpty()"
    ng-click="$ctrl.clear()"
    ></combobox-option>
  <combobox-option-group
    ng-repeat-start="(group, items) in $ctrl.groupedOptions track by group"
    deprecated-ng-click="$ctrl.toggleItemsGroup(items)"
    >{{group}}</combobox-option-group>
  <combobox-option
    ng-repeat-end
    ng-repeat="$item in items track by $ctrl.trackByFnc($item, $index)"
    selected="$ctrl.isSelected($item)"
    ng-click="$ctrl.toggleItem($item)"
    value="$item"
    >{{ $ctrl.displayByFunction({ $item }) }}</combobox-option>
</combobox-menu>`

function isEmptyValue(value) {
  return value === undefined || value === null || (Array.isArray(value) && value.length === 0)
}

class ComboboxComponent {
  model = []
  viewValue = ''
  isOpen = false
  filterNeedle = ''
  filteredOptions = []
  groupedOptions = { '': [] }
  disabled = false
  readonly = false
  editable = true
  menuElement = null
  displayByFunction = (value) => value
  selectByFunction = (value) => value
  trackByFunction = (value) => value
  ngModelIsEmpty = (value) => isEmptyValue(value)
  ngModelRender = () => this.render()

  constructor($attrs, $compile, $document, $element, $filter, $parse, $q, $scope, $timeout, KdTopLayerService) {
    this.$attrs = $attrs
    this.$compile = $compile
    this.$document = $document
    this.$element = $element
    this.$filter = $filter
    this.$parse = $parse
    this.$q = $q
    this.$scope = $scope
    this.$timeout = $timeout
    this.KdTopLayerService = KdTopLayerService

    this.multiple = 'multiple' in $attrs
    this.filterByFunction = $filter('filter')
    this.groupByFunction = $filter('groupBy')
    this.orderByFunction = $filter('orderBy')
    this.menuElementCompiler = $compile(comboboxMenuTemplate)
  }

  $onInit() {
    this.$element.on('click', (event) => this.onClick(event))
    this.$element.on('keypress', (event) => this.onKeypress(event))

    this.$attrs.$set('tabindex', '0')
    this.$attrs.$observe('required', (required) => this.setRequired(required))
    this.$attrs.$observe('readonly', (readonly) => this.setReadOnly(readonly))
    this.$attrs.$observe('disabled', (disabled) => this.setDisabled(disabled))

    const $setTouched = this.ngModel.$setTouched.bind(this.ngModel)
    this.ngModel.$setTouched = () => !this.isOpen && $setTouched()
    this.ngModel.$render = this.ngModelRender
    this.ngModel.$isEmpty = this.ngModelIsEmpty
  }

  $onChanges(changes) {
    if (changes.displayBy) this.displayByFunction = this.$parse(this.displayBy)
    if (changes.selectBy) this.selectByFunction = this.$parse(this.selectBy)
    if (changes.trackBy) this.trackByFunction = this.$parse(this.trackBy)

    if (changes.options) {
      this.options = this.orderByFunction(changes.options.currentValue, this.orderBy || '')
      this.filterOptions()
      this.render()
    }
  }

  $onDestroy() {
    this.destroyMenu()
  }

  setRequired(required) {
    this.required = required
  }

  setReadOnly(readonly) {
    this.readonly = readonly
    this.updateEditable()
  }

  setDisabled(disabled) {
    this.disabled = disabled
    this.$attrs.$set('tabindex', disabled ? null : '0')
    this.updateEditable()
  }

  toggleMenu(isOpen) {
    if (!this.editable) {
      return this.$q.reject()
    }

    this.filterNeedle = ''

    this.isOpen = isOpen

    if (!this.isOpen) {
      this.ngModel.$setTouched()
      this.setView()
    }

    return this.$timeout(() => {
      if (isOpen) this.createMenu()
      else this.destroyMenu()
    })
  }

  createMenu() {
    if (!this.menuElement) {
      this.menuElementCompiler(this.$scope.$new(), (clone, scope) => {
        this.menuElement = { clone, scope }
        this.KdTopLayerService.add(clone, { modal: false, restoreFocus: true })
      })
    }
  }

  destroyMenu() {
    if (this.menuElement) {
      this.KdTopLayerService.remove(this.menuElement.clone)
      this.menuElement.clone.remove()
      this.menuElement.scope.$destroy()
      this.menuElement = null
    }
  }

  trackByFnc($item, $index) {
    return this.trackByFunction({ $item, $index })
  }

  updateEditable() {
    this.editable = !this.disabled && !this.readonly

    if (!this.editable && this.isOpen) this.toggleMenu(false)
  }

  filterOptions(needle) {
    this.filterNeedle = needle
    this.filteredOptions = this.filterNeedle ? this.filterByFunction(this.options, this.filterNeedle) : this.options

    this.groupedOptions = this.groupBy
      ? this.groupByFunction(this.filteredOptions, this.groupBy)
      : { '': this.filteredOptions }
  }

  clear() {
    if (!this.editable) return

    this.setModel([])
  }

  isEmpty() {
    return this.model.length === 0
  }

  isSelected(item) {
    return this.model.indexOf(item) >= 0
  }

  toggleItem(item) {
    if (!this.editable) return

    const model = [...this.model]

    if (!this.multiple) model.length = 0

    if (model.indexOf(item) >= 0) {
      model.splice(model.indexOf(item), 1)
    } else {
      model.push(item)
    }

    this.setModel(model)
  }

  toggleItems(selectAll) {
    if (!this.editable) return

    const model = [...this.model]

    if (selectAll) {
      for (const item of this.filteredOptions) {
        if (model.indexOf(item) < 0) {
          model.push(item)
        }
      }
    } else {
      for (const item of this.filteredOptions) {
        if (model.indexOf(item) >= 0) {
          model.splice(model.indexOf(item), 1)
        }
      }
    }

    this.setModel(model)
  }

  toggleItemsGroup(group) {
    if (!this.multiple || !this.editable) return

    const model = [...this.model]
    const selectAll = group.some((item) => model.indexOf(item) < 0)

    if (selectAll) {
      for (const item of group) {
        if (model.indexOf(item) < 0) {
          model.push(item)
        }
      }
    } else {
      for (const item of group) {
        if (model.indexOf(item) >= 0) {
          model.splice(model.indexOf(item), 1)
        }
      }
    }

    this.setModel(model)
  }

  render() {
    const model = [].concat(this.ngModel.$modelValue)

    this.model = Array.isArray(this.options)
      ? this.options.filter(
          (item) => !isEmptyValue(model.find((val) => equals(this.selectByFunction({ $item: item }), val))),
        )
      : []

    this.setView()
  }

  setModel(model) {
    this.model = model
    model = model.map((item) => this.selectByFunction({ $item: item }))

    this.ngModel.$setViewValue(this.multiple ? model : model[0])
  }

  setView() {
    this.viewValue = this.model.map((item) => this.displayByFunction({ $item: item })).join(', ')
  }

  onClick() {
    if (this.editable && !this.isOpen) {
      this.toggleMenu(true)
    }
  }

  onKeypress(event) {
    if (event.code === 'Space') {
      this.toggleMenu(true)
    }
  }
}

module.component('combobox', {
  controller: ComboboxComponent,
  template,
  require: {
    ngModel: 'ngModel',
  },
  bindings: {
    options: '<',
    selectBy: '@',
    trackBy: '@',
    orderBy: '@',
    groupBy: '@',
    displayBy: '@',
  },
  transclude: true,
})
