import module from '@admin/usuario/module'

export const adminUsuarioSesionSchema = {
  acceso: {
    admin: 'boolean',
  },
}

export class AdminUsuarioSesionService {
  constructor($http) {
    this.$http = $http
  }

  get(idUsuario) {
    return this.$http.get(`api/usuario/${idUsuario}/sesion`, {
      schema: adminUsuarioSesionSchema,
    })
  }
}

module.service('AdminUsuarioSesionService', AdminUsuarioSesionService)
