import module from '@kendu/ui/module'

class KdFormDataInterceptor {
  request = (config) => {
    if (config.data instanceof FormData) {
      config.transformRequest = (v) => v
      delete config.headers?.['Content-Type']
    }

    return config
  }
}

module.service('KdFormDataInterceptor', KdFormDataInterceptor)
