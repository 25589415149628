import module from '@admin/usuario/module'

export const adminUsuarioInvitacionSchema = {
  inicializado: 'boolean',
  pendientes: 'number',
}

export class AdminUsuarioInvitacionService {
  constructor($http) {
    this.$http = $http
  }

  get(idUsuario) {
    return this.$http.get(`api/usuario/${idUsuario}/invitacion`, {
      schema: adminUsuarioInvitacionSchema,
    })
  }

  put(idUsuario, data) {
    return this.$http.put(`api/usuario/${idUsuario}/invitacion`, data, {
      schema: adminUsuarioInvitacionSchema,
    })
  }
}

module.service('AdminUsuarioInvitacionService', AdminUsuarioInvitacionService)
