import angular from 'angular'
import admin from '@admin'
import app from '@app'
import deprecated from 'kendu/legacy'
import finances from '@finances'
import kendu from '@kendu'
import './style.scss'

angular.element(() => angular.bootstrap(document, [admin, app, deprecated, finances, kendu], { strictDi: true }))

function about() {
  console.info(process.env.ABOUT)
}

window.about = about
