import module from 'kendu/legacy/module'

module.directive('uiAsyncValidators', () => ({
  restrict: 'A',
  require: 'ngModel',
  link(scope, element, attrs, ngModel) {
    const validators = scope.$eval(attrs.uiAsyncValidators)

    Object.entries(validators).forEach(([key, value]) => (ngModel.$asyncValidators[key] = value))
  },
}))
