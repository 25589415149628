import module from '@kendu/ui/module'

module.config(($httpProvider) => {
  $httpProvider.interceptors.push('KdFileDownloadInterceptor')
  $httpProvider.interceptors.push('KdCacheInterceptor')
  $httpProvider.interceptors.push('KdTransformInterceptor')
  $httpProvider.interceptors.push('KdFormDataInterceptor')
  $httpProvider.interceptors.push('KdAuthInterceptor')
  $httpProvider.interceptors.push('KdErrorInterceptor')
  $httpProvider.defaults.paramSerializer = '$httpParamSerializerJQLike'
})
