import fileSaver from 'file-saver'

export class FileResponse {
  constructor(content, filename = '') {
    this.content = content
    this.filename = decodeURIComponent(filename)
  }

  open(target = '_blank') {
    const url = window.URL.createObjectURL(this.content)
    const win = window.open(url, target)

    if (win) {
      win.focus()

      const timer = setInterval(() => {
        if (win.closed) {
          window.URL.revokeObjectURL(url)
          clearInterval(timer)
        }
      }, 1000)

      return true
    } else {
      return false
    }
  }

  save(filename = this.filename) {
    fileSaver.saveAs(this.content, filename)
  }
}
