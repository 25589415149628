import module from '@kendu/ui/module'
import template from './template.html'
import './style.scss'
import './cell/component.js'
import './column/component.js'
import './columns/component.js'
import './header/component.js'
import './headers/component.js'
import './row/component.js'

export class KdTableComponent {
  constructor($element) {
    this.$element = $element
  }

  $onInit() {
    const box = this.$element[0].getBoundingClientRect()

    this.$element.css('max-height', `calc(100vh - ${box.y}px)`)
  }
}

module.component('kdTable', {
  controller: KdTableComponent,
  template,
  transclude: {
    columns: 'kdTableColumn',
  },
  bindings: {
    data: '<',
  },
})
