import module from '@kendu/ui/module'

export function KdTaskRunnerFactory($q) {
  class KdTaskRunner {
    constructor(task, config) {
      this.task = task
      this.limit = (config && config.limit) || 10
      this.items = {
        total: [],
        pending: [],
        processing: [],
        completed: [],
        error: [],
      }
      this.results = []
      this.ignoreError = config && !!config.ignoreError
      this.onComplete = () => {}
      this.onError = () => {}
      this.onProgress = () => {}
      this.progress = 0
      this.status = {
        pending: false,
        processing: false,
        completed: false,
        error: false,
      }
      this.total = {
        pending: 0,
        processing: 0,
        completed: 0,
        error: 0,
      }
      this.q = $q
    }

    add(item) {
      this.items.total.push(item)
      this.items.pending.push(item)
      this.status.pending = true
      this.total.pending = this.items.pending.length
    }

    next() {
      if (this.status.processing && this.items.pending.length && this.items.processing.length < this.limit) {
        const item = this.items.pending.shift()

        this.items.processing.push(item)

        this.q
          .when(this.task(item))
          .then(
            (result) => {
              this.items.processing.splice(this.items.processing.indexOf(item), 1)

              this.items.completed.push(item)
              this.results.push(result)

              this.onProgress(item, result)
            },
            (error) => {
              this.items.processing.splice(this.items.processing.indexOf(item), 1)

              this.status.error = true
              this.status.processing = (this.ignoreError && this.status.processing) || false
              this.items.error.push(item)
              this.results.push(error)

              this.onError(item, error)
            },
          )
          .finally(() => {
            this.updateProgress()

            if (this.status.completed) {
              this.onComplete(this.results)
            } else if (!this.status.error || this.ignoreError) {
              this.next()
            }
          })

        this.next()
      }

      this.updateProgress()
    }

    start() {
      if (!this.status.pending) return

      this.status.processing = true

      this.next()
    }

    stop() {
      this.status.processing = false
    }

    updateProgress() {
      this.progress = (this.items.completed.length + this.items.error.length) / this.items.total.length
      this.status.completed = this.items.completed.length + this.items.error.length === this.items.total.length
      this.status.pending = this.items.pending.length > 0

      this.total.pending = this.items.pending.length
      this.total.processing = this.items.processing.length
      this.total.completed = this.items.completed.length
      this.total.error = this.items.error.length
    }
  }

  return KdTaskRunner
}

module.factory('KdTaskRunner', KdTaskRunnerFactory)
