import module from '@app/ui/module'
import template from './template.html'

export class AppUiSyncComponent {
  constructor($rootScope, $state, AppHttpCache) {
    this.$rootScope = $rootScope
    this.$state = $state
    this.AppHttpCache = AppHttpCache
    this.processing = false
  }

  $onInit() {
    this.$rootScope.$on('KdLoadIndicator:start', () => (this.processing = true))
    this.$rootScope.$on('KdLoadIndicator:complete', () => (this.processing = false))
  }

  sync() {
    if (this.processing) return

    this.AppHttpCache.destroy()

    this.$state.reload()
  }
}

module.component('appUiSync', {
  controller: AppUiSyncComponent,
  template,
})
