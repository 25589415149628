import module from '@kendu/ui/module'
import { element } from 'angular'
import './style.scss'

export class KdProgressComponent {
  constructor($element) {
    this.progress = 0
    this.value = undefined
    this.bgr = element('<div kd-progress__bgr></div>')
    this.bar = element('<div kd-progress__bar></div>')

    $element.append(this.bgr)
    $element.append(this.bar)
  }

  $onChanges(changes) {
    if (changes.value) {
      this.progress = Math.max(0, Math.min(1, parseFloat(this.value))) * 100
    }

    this.update()
  }

  update() {
    this.bar.css('width', this.progress + '%')
  }
}

module.component('kdProgress', {
  controller: KdProgressComponent,
  template: '',
  bindings: {
    value: '@',
  },
})
