import module from 'kendu/legacy/module'

function $jwt() {
  this.decode = decode
  this.getExpirationDate = getExpirationDate
  this.isExpired = isExpired

  function decode(jwt) {
    if (typeof jwt === 'string') {
      const parts = jwt.split('.')

      if (parts.length !== 3) return null

      const decoded = urlBase64Decode(parts[1])

      return decoded ? JSON.parse(decoded) : null
    } else {
      return null
    }
  }

  function getExpirationDate(jwt) {
    const decoded = decode(jwt)

    if (decoded && decoded.exp) {
      return new Date(decoded.exp * 1000)
    } else {
      return null
    }
  }

  function isExpired(jwt) {
    const expDate = getExpirationDate(jwt)

    if (!(expDate instanceof Date)) return true

    return expDate < new Date()
  }

  function urlBase64Decode(str) {
    let output = str.replace('-', '+').replace('_', '/')

    switch (output.length % 4) {
      case 0:
        break
      case 2:
        output += '=='
        break
      case 3:
        output += '='
        break
      default:
        return null
    }
    return window.atob(output) // polifyll https://github.com/davidchambers/Base64.js
  }
}

module.service('$jwt', $jwt)
