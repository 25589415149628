import module from '@kendu/ui/module'

export class KdModalService {
  modals = new Map()

  constructor($document, KdTopLayerService) {
    this.$document = $document
    this.KdTopLayerService = KdTopLayerService
  }

  add($element) {
    if (this.modals.has($element)) return

    const previousFocusedElement = this.$document[0].activeElement
    this.modals.set($element, { previousFocusedElement })
    this.KdTopLayerService.add($element, { modal: true, restoreFocus: true })
  }

  remove($element) {
    if (!this.modals.has($element)) return

    this.KdTopLayerService.remove($element)
    this.modals.delete($element)
  }
}

module.service('KdModalService', KdModalService)
