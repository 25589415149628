import module from '@admin/usuario/module'
import template from './template.html'

export class AdminUsuarioSesionHistorialListaComponent {
  constructor(AdminUsuarioSesionHistorialService, AppTimeService) {
    this.idUsuario = undefined
    this.fecha = undefined
    this.AdminUsuarioSesionHistorialService = AdminUsuarioSesionHistorialService
    this.appTimeService = AppTimeService
  }

  $onChanges(changes) {
    if (changes.fecha) this.cargar()
  }

  cargar() {
    this.data = undefined

    if (!(this.fecha instanceof Date)) return

    this.promise = this.AdminUsuarioSesionHistorialService.get(this.idUsuario, this.fecha).then((response) => {
      this.data = response.data.map((item) => {
        item.agrupacion = this.appTimeService.weekdayDayGroup(item.fecha)

        return item
      })
    })
  }
}

module.component('adminUsuarioSesionHistorialLista', {
  controller: AdminUsuarioSesionHistorialListaComponent,
  template,
  bindings: {
    idUsuario: '<',
    fecha: '<',
  },
})
