import module from '@admin/usuario/module'

export const adminUsuarioInformacionSchema = {}

export class AdminUsuarioInformacionService {
  constructor($http) {
    this.$http = $http
  }

  get(idUsuario) {
    return this.$http.get(`api/usuario/${idUsuario}/informacion`, {
      schema: adminUsuarioInformacionSchema,
    })
  }
}

module.service('AdminUsuarioInformacionService', AdminUsuarioInformacionService)
