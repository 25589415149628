import module from './module.js'
/* Components */
import './components/component.js'
import './components/launcher/component.js'
/* Routes */
import './routes/dashboard.js'
/* Services */
import './services/App/Dashboard.js'

export default module.name
