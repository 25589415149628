import module from '@kendu/ui/module'
import './style.scss'

export class KxBackdropDirective {
  constructor($element, $scope, KdBackdropService, KdElementScrollClipService) {
    this.$element = $element
    this.$scope = $scope
    this.KdBackdropService = KdBackdropService
    this.enabled = undefined
    this.scrollClip = KdElementScrollClipService.create($element)
  }

  $onInit() {
    this.$scope.$watch(this.$element.attr('kd-backdrop'), (kdBackdrop) => (this.enabled = kdBackdrop !== false))
    this.$scope.$watch(
      () => this.isVisible(),
      (visible) => this.update(visible),
    )
  }

  $onDestroy() {
    this.KdBackdropService.hide(this.$element)
    this.scrollClip.disable()
    this.scrollClip = null
  }

  isVisible() {
    if (this.$element[0].offsetParent === null) {
      return false
    }

    return this.enabled
  }

  update(visible) {
    if (visible) {
      this.KdBackdropService.show(this.$element)
      this.scrollClip.enable()
    } else {
      this.KdBackdropService.hide(this.$element)
      this.scrollClip.disable()
    }
  }
}

module.directive('kdBackdrop', () => ({
  controller: KxBackdropDirective,
  restrict: 'A',
}))
