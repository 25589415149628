import module from '@kendu/ui/module'
import { element } from 'angular'

export class KdElementScrollClipService {
  constructor($window, KdResizeObserverService) {
    this.$window = $window
    this.resizeObserver = KdResizeObserverService
    this.defaultContainer = $window.document.body
  }

  create(element) {
    const container = findContainer(element)
    let scrollTop = null
    let scrolled = false
    let enabled = false

    element.on('destroy', () => disable())

    const render = () => {
      const elementTop = getOffset(element[0], container[0])
      const elementHeight = element[0].clientHeight
      const containerTop = getOffset(container[0])
      const containerHeight = container[0].scrollHeight
      const windowHeight = this.$window.innerHeight
      const availableHeight = Math.round(windowHeight - containerTop)
      const marginTop = clamp(0, availableHeight - elementHeight, elementTop)
      const marginBottom = clamp(0, availableHeight - elementHeight, containerHeight - elementTop - elementHeight)
      const containerClip = Math.max(marginTop + elementHeight + marginBottom, availableHeight)
      const containerScroll = elementTop - marginTop

      this.$window.requestAnimationFrame(() => {
        container[0].style.height = `${containerClip}px`
        container[0].scroll(0, containerScroll)

        if (!scrolled) {
          this.$window.scroll(0, scrollTop - containerScroll)
          scrolled = true
        }
      })
    }

    const enable = () => {
      if (enabled) return

      enabled = true

      scrollTop = this.$window.scrollY
      scrolled = false

      container.css('overflow', 'hidden')

      this.$window.addEventListener('resize', render)
      this.resizeObserver.observe(element[0], render)
    }

    const disable = () => {
      if (!enabled) return

      enabled = false

      this.$window.removeEventListener('resize', render)
      this.resizeObserver.unobserve(element[0], render)

      const scrollTop = container[0].scrollTop + this.$window.scrollY

      container.css('overflow', null)
      container.css('min-height', null)
      container.css('height', null)

      this.$window.scroll(0, scrollTop)
    }

    return { enable, disable }
  }
}

function clamp(min, val, max) {
  return Math.max(min, Math.min(val, max))
}

function getOffset(element, parent) {
  let offset = 0

  while (element && element !== parent && !isNaN(element.offsetTop)) {
    offset += element.offsetTop

    element = element.offsetParent
  }

  return offset
}

function findContainer(node) {
  return element(node[0].closest('kd-content') || this.defaultContainer)
}

module.service('KdElementScrollClipService', KdElementScrollClipService)
