import module from '@app/ui/module'
import template from './template.html'
import './style.scss'

export class AppUiToolbarComponent {
  isLoading = false

  constructor($element, $rootScope) {
    this.$element = $element
    this.$rootScope = $rootScope
  }

  $onInit() {
    this.$element.attr('role', 'banner')
    this.removeStartListener = this.$rootScope.$on('KdLoadIndicator:start', () => (this.isLoading = true))
    this.removeStopListener = this.$rootScope.$on('KdLoadIndicator:complete', () => (this.isLoading = false))
  }

  $onDestroy() {
    this.removeStartListener()
    this.removeStopListener()
  }
}

module.component('appUiToolbar', {
  controller: AppUiToolbarComponent,
  template,
  bindings: {
    search: '<',
    user: '<',
    login: '&',
    logout: '&',
  },
})
