import module from '@admin/usuario/module'

module.config(($stateProvider) => {
  $stateProvider.state('usuario.permisos.cuentas', {
    url: '/cuentas',
    data: { title: 'Cuentas bancarias' },
    views: {
      '@usuario': { component: 'adminUsuarioPermisosCuentas' },
    },
  })
})
