import module from '@app/excel/module'
import template from './template.html'

export class AppExcelImportarComponent {
  constructor() {
    this.headers = undefined
    this.model = undefined
    this.onChange = undefined
    this.data = null
  }

  $onChanges(changes) {
    if (changes.headers) {
      const headers = this.headers || []

      this.headers = headers.map((config) => {
        return Object.assign(
          {
            id: undefined,
            name: undefined,
            group: undefined,
            parser: (v) => v,
            processor: (v) => v,
            match: () => {},
          },
          config,
        )
      })
    }
  }

  actualizar(data) {
    this.data = data

    this.procesar()
  }

  decodificar(data) {
    this.data = {
      headers: data.headers.map((value) =>
        value ? this.headers.find((item) => item.match(value) === true) : undefined,
      ),
      rows: data.rows,
    }

    this.procesar()
  }

  procesar() {
    let data
    let headers

    if (this.data) {
      headers = this.data.headers.map((item) => item && item.id)

      const Model = this.model

      data = this.data.rows.map((celdas) => {
        const model = this.model ? new Model() : {}

        return this.data.headers.reduce((object, header, index) => {
          if (!header) return object

          const cell = celdas[index]
          const value = header.parser(cell)

          return header.processor(object, value)
        }, model)
      })
    }

    this.onChange({ $event: { data, headers, rows: data } })
  }
}

module.component('appExcelImportar', {
  controller: AppExcelImportarComponent,
  template,
  bindings: {
    headers: '<',
    model: '<',
    onChange: '&',
  },
})
