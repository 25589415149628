const FOCUSABLE_ELEMENTS_SELECTOR =
  'a[href]:not([disabled]), ' +
  'area[href]:not([disabled]), ' +
  'button:not([disabled]), ' +
  'textarea:not([disabled]), ' +
  'input:not([disabled]), ' +
  'select:not([disabled]), ' +
  'iframe, ' +
  'object, ' +
  '[tabindex="0"], ' +
  '[contenteditable]'

export function getFocusableElements(container) {
  return Array.from(container.querySelectorAll(FOCUSABLE_ELEMENTS_SELECTOR))
}

export function focusElement(element) {
  element.focus({ focusVisible: true })

  if (element.ownerDocument.activeElement !== element) return false

  if (!element.matches(':scope:focus-visible')) {
    element.classList.add(':focus-visible')
    element.addEventListener('blur', () => element.classList.remove(':focus-visible'), { once: true })
  }

  return true
}

export function allChildrenInert(parent, exceptPredicate = () => false) {
  const children = new Set()

  const setAll = (nodes, isInert) => {
    for (const node of nodes) {
      if (node.nodeType !== 1 || exceptPredicate(node)) continue
      node.toggleAttribute('inert', isInert)
      isInert ? children.add(node) : children.delete(node)
    }
  }

  const mutationObserver = new MutationObserver((records) => {
    for (const { addedNodes, removedNodes } of records) {
      setAll(addedNodes, true)
      setAll(removedNodes, false)
    }
  })

  setAll(parent.children, true)
  mutationObserver.observe(parent, { childList: true })

  return {
    disconnect() {
      mutationObserver.disconnect()
      setAll(children, false)
    },
  }
}
