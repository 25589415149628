import module from '@app/inicio/module'
import template from './template.html'
import Trianglify from 'trianglify'
import cssx from 'cssx'
import './style.scss'

export class AppInicioComponent {
  onSchemeChange = () => this.updateBackground()

  constructor($window, AppUiThemeService) {
    this.$window = $window
    this.appUiThemeService = AppUiThemeService
  }

  $onInit() {
    this.appUiThemeService.addEventListener('change', this.onSchemeChange)
    this.updateBackground()
  }

  $onDestroy() {
    this.appUiThemeService.removeEventListener('change', this.onSchemeChange)
  }

  bgConfig = {
    light: {
      logo: { fill: '#c3a574' },
      texto: { fill: '#816f5e' },
      xColors: ['#f0ede6', '#f0ede6', '#a5cabd'],
      yColors: ['#f2e7cf', '#84cccd', '#0090a3'],
    },
    dark: {
      logo: { fill: '#ebcfa3' },
      texto: { fill: '#4e4a46' },
      xColors: ['#f0ede6', '#9ec1b9', '#388294'],
      yColors: ['#a2b9be', '#628482', '#0c5d73'],
    },
  }

  image = {
    light: null,
    dark: null,
  }

  updateBackground() {
    this.scheme = this.appUiThemeService.scheme

    const config = this.bgConfig[this.scheme]
    const size = Math.max(this.$window.screen.width, this.$window.screen.height)

    if (!this.image[this.scheme]) {
      this.image[this.scheme] = Trianglify({
        seed: 1,
        width: size,
        height: size,
        variance: 0.75,
        cell_size: 0.1 * size,
        stroke_width: 0,
        x_colors: config.xColors,
        y_colors: config.yColors,
      }).png()
    }

    const css = {
      '--bgImage': `url(${this.image[this.scheme]})`,
    }

    let stylesheet = this.stylesheet

    if (!stylesheet) {
      stylesheet = this.stylesheet = cssx()
      stylesheet.add({ 'app-inicio': css })
    } else {
      stylesheet.update({ 'app-inicio': css })
    }
  }
}

module.component('appInicio', {
  controller: AppInicioComponent,
  template,
})
