import module from './module.js'
/* Components */
import './components/component.js'
import './components/informacion/component.js'
import './components/informacion/contacto/component.js'
import './components/informacion/personal/component.js'
import './components/invitacion/component.js'
import './components/permisos/component.js'
import './components/permisos/clientes/component.js'
import './components/permisos/cuentas/component.js'
import './components/permisos/empresas/component.js'
import './components/permisos/modulos/component.js'
import './components/permisos/modulos/item/component.js'
import './components/permisos/proyectos/component.js'
import './components/sesion/component.js'
import './components/sesion/acceso/component.js'
import './components/sesion/historial/component.js'
import './components/sesion/historial/lista/component.js'
/* Services */
import './services/Usuario.js'
import './services/Usuario/Informacion.js'
import './services/Usuario/Informacion/Contacto.js'
import './services/Usuario/Informacion/Personal.js'
import './services/Usuario/Invitacion.js'
import './services/Usuario/Permisos.js'
import './services/Usuario/Permisos/Clientes.js'
import './services/Usuario/Permisos/Cuentas.js'
import './services/Usuario/Permisos/Empresas.js'
import './services/Usuario/Permisos/Modulos.js'
import './services/Usuario/Permisos/Proyectos.js'
import './services/Usuario/Sesion.js'
import './services/Usuario/Sesion/Acceso.js'
import './services/Usuario/Sesion/Historial.js'
/* Routes */
import './routes/usuario.js'
import './routes/usuario/informacion.js'
import './routes/usuario/invitacion.js'
import './routes/usuario/permisos.js'
import './routes/usuario/permisos/clientes.js'
import './routes/usuario/permisos/cuentas.js'
import './routes/usuario/permisos/empresas.js'
import './routes/usuario/permisos/modulos.js'
import './routes/usuario/permisos/proyectos.js'
import './routes/usuario/sesion.js'
import './routes/usuario/sesion/historial.js'

export default module.name
