import module from '@app/sesion/module'
import template from './template.html'

export class AppSesionRegistrarAyudaComponent {
  constructor() {
    this.modal = undefined
  }

  cerrar() {
    this.modal.close()
  }
}

module.component('appSesionRegistrarAyuda', {
  controller: AppSesionRegistrarAyudaComponent,
  template,
  require: {
    modal: 'kdModal',
  },
})
