import module from '@admin/usuario/module'
import template from './template.html'
import orderBy from 'lodash-es/orderBy.js'

export class AdminUsuarioPermisosProyectosComponent {
  constructor($filter, AdminUsuarioPermisosProyectosService) {
    this.idUsuario = undefined
    this.onClose = undefined
    this.AdminUsuarioPermisosProyectosService = AdminUsuarioPermisosProyectosService
    this.filterBy = $filter('filter')
    this.agrupacion = 'agrupacion'
    this.editando = false
    this.error = null
    this.filtro = ''
    this.resultados = []
    this.seleccionados = []
  }

  $onInit() {
    this.promise = this.AdminUsuarioPermisosProyectosService.get(this.idUsuario).then((response) => {
      this.permisos = orderBy(
        response.data.map((item) => {
          item.agrupacion = item.operadora

          return item
        }),
        ['operadora', 'descripcion'],
      )

      this.data = this.permisos.filter((item) => item.visible)

      this.editar(false)
    })
  }

  aceptar() {
    if (this.procesando) return

    this.error = null
    this.procesando = true

    const data = this.permisos
      .filter((item) => item.activo)
      .map((item) => item.id_proyecto)
      .sort()

    this.AdminUsuarioPermisosProyectosService.put(this.idUsuario, data)
      .then(() => this.onClose())
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }

  editar(editando) {
    this.editando = editando
    this.lista = this.data.filter((item) => (this.editando ? true : item.activo))

    this.filtrar()
  }

  filtrar() {
    this.resultados = this.filterBy(this.lista, this.filtro)

    this.totalizar()
  }

  seleccionar(item) {
    item.activo = !item.activo

    this.totalizar()
  }

  seleccionarToggle() {
    this.resultados.forEach((item) => (item.activo = !this.todosSeleccionados))

    this.totalizar()
  }

  totalizar() {
    this.todosSeleccionados = this.resultados.every((item) => item.activo)
    this.seleccionados = this.lista.filter((item) => item.activo)
  }
}

module.component('adminUsuarioPermisosProyectos', {
  controller: AdminUsuarioPermisosProyectosComponent,
  template,
  bindings: {
    idUsuario: '<',
    onClose: '&',
  },
})
