import module from '@admin/usuario/module'

export const adminUsuarioSesionAccesoSchema = {
  admin: 'boolean',
  duracion: 'number',
}

export class AdminUsuarioSesionAccesoService {
  constructor($http) {
    this.$http = $http
  }

  get(idUsuario) {
    return this.$http.get(`api/usuario/${idUsuario}/sesion/acceso`, {
      schema: adminUsuarioSesionAccesoSchema,
    })
  }

  put(idUsuario, data) {
    return this.$http.put(`api/usuario/${idUsuario}/sesion/acceso`, data, {
      schema: adminUsuarioSesionAccesoSchema,
    })
  }
}

module.service('AdminUsuarioSesionAccesoService', AdminUsuarioSesionAccesoService)
