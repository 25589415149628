import module from '@kendu/document/module'
import template from './template.html'
import fileSaver from 'file-saver'
import './style.scss'
import { element } from 'angular'

class KdDocumentComponent {
  constructor($document, $element, $http, $log, $q, $sce, $scope, $window) {
    this.$document = $document
    this.$element = $element
    this.$http = $http
    this.$window = $window
    this.$q = $q
    this.$sce = $sce
    this.$scope = $scope
    this.$log = $log
    this.defer = null
    this.loading = false
  }

  $onChanges(changes) {
    element(this.$document[0].body).append(this.$element)

    if (changes.src) {
      if (typeof this.src === 'string') {
        this.src = this.$sce.getTrustedResourceUrl(this.src)
        this.load()
      } else {
        this.clear()
      }
    }
  }

  $onInit() {
    if (this.appInterface) {
      this.appInterface.setModal(true)
    }
  }

  $onDestroy() {
    this.clear()
    this.$element.remove()

    if (this.appInterface) {
      this.appInterface.setModal(false)
    }
  }

  clear() {
    this.error = null

    if (this.defer) {
      this.defer.resolve()
      this.defer = null
    }

    if (this.url) {
      this.$window.URL.revokeObjectURL(this.url)
      this.url = null
    }

    this.file = null
  }

  close() {
    this.onClose()
  }

  download() {
    if (this.file) {
      fileSaver.saveAs(this.file, this.file.name)
    }
  }

  extractFilename(contentDisposition) {
    const filenameRegExp = /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/i

    return (filenameRegExp.exec(contentDisposition) || [])[2]
  }

  load() {
    this.clear()

    this.defer = this.$q.defer()

    this.loading = true

    const config = {
      method: 'GET',
      url: this.src,
      responseType: 'blob',
      timeout: this.defer.promise,
    }

    this.$http(config)
      .then((response) => {
        this.file = response.data
        this.url = this.$sce.trustAsResourceUrl(this.$window.URL.createObjectURL(this.file))

        this.file.name = this.filename || this.extractFilename(response.headers('Content-Disposition'))

        this.onLoad({ $event: { data: this.file } })
      })
      .catch((response) => {
        const reader = new this.$window.FileReader()

        reader.addEventListener('loadend', (event) => {
          this.$scope.$apply(() => {
            this.error = JSON.parse(event.srcElement.result)

            this.$log.error('Error: ' + this.error)
          })
        })

        reader.readAsText(response.data)
      })
      .finally(() => {
        this.loading = false
        this.defer = null
      })
  }

  reload() {
    this.load()
  }
}

module.component('kdDocument', {
  controller: KdDocumentComponent,
  template,
  require: {
    appInterface: '?^appInterface',
  },
  bindings: {
    name: '@',
    src: '<',
    filename: '@',
    onClose: '&',
    onLoad: '&',
  },
})
