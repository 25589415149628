import module from '@admin/usuario/module'
import template from './template.html'

export class AdminUsuarioSesionHistorialComponent {
  constructor($location) {
    this.idUsuario = undefined
    this.periodo = undefined
    this.mes = undefined
    this.$location = $location
  }

  $onChanges(changes) {
    if (changes.periodo && (!Number.isFinite(this.periodo) || this.periodo < 0 || this.periodo > 9999)) {
      this.periodo = new Date().getFullYear()
    }

    if (changes.mes && (!Number.isFinite(this.mes) || this.mes < 1 || this.mes > 12)) {
      this.mes = new Date().getMonth() + 1
    }

    if (changes.periodo || changes.mes) {
      this.fecha = new Date(this.periodo, this.mes - 1)
    }
  }

  anterior() {
    this.fecha = new Date(this.fecha.getFullYear(), this.fecha.getMonth() - 1)

    this.buscar()
  }

  buscar() {
    this.$location.search({
      periodo: this.fecha.getFullYear(),
      mes: this.fecha.getMonth() + 1,
    })

    this.$location.replace()
  }

  siguiente() {
    this.fecha = new Date(this.fecha.getFullYear(), this.fecha.getMonth() + 1)

    this.buscar()
  }
}

module.component('adminUsuarioSesionHistorial', {
  controller: AdminUsuarioSesionHistorialComponent,
  template,
  bindings: {
    idUsuario: '<',
    periodo: '<',
    mes: '<',
  },
})
